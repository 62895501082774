import { GET_ALL_ADMINS } from "./adminTypes";

const initialState = {
  allAdmins: [],
  allAdminsLoading: true,
  registrationToken: null,
};

export default function adminsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ADMINS:
      return {
        ...state,
        allAdminsLoading: false,
        allAdmins: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
