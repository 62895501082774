import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  getPrice,
  getPriceUsingCredit,
  plans,
} from '../../components/subscription/util';
import Divider from '@mui/material/Divider';
import SubscriptionActions from './SubscriptionActions';
import Modal from '@mui/material/Modal';
import RenewPlanForm from './RenewPlanForm';
import { useSelector } from 'react-redux';

export default function CurrentPlan() {
  const [showRenewPlanForm, setShowRenewPlanForm] = useState(false);
  const [type, setType] = useState('');

  const openRenewPlanForm = () => setShowRenewPlanForm(true);
  const closeRenewPlanForm = () => setShowRenewPlanForm(false);

  const { hospital } = useSelector((state) => state.subscription);

  return (
    <Paper
      sx={{
        padding: 5,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          marginBottom: 5,
          fontSize: {
            xs: '1.2rem',
            sm: '2rem',
          },
        }}
      >
        Your Current Plan
      </Typography>

      <Box
        key={hospital?.subscription?.plan?.title}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getPriceUsingCredit(
            hospital?.subscription?.currency,
            hospital?.subscription?.totalBasePrice,
            hospital?.subscription?.totalPrice
          )}
        </Box>
        {hospital?.subscription?.serviceCharge > 0 && (
          <Typography variant="body2">
            Service Charge {hospital?.subscription?.serviceCharge}
          </Typography>
        )}
        <Typography mb={1} variant="h3">
          {hospital?.subscription?.plan?.title}
        </Typography>
        <Typography variant="body2">
          {hospital?.subscription?.usersCount} users
        </Typography>
        <Typography variant="body2">
          {hospital?.subscription?.subtitle}{' '}
        </Typography>
      </Box>

      <Box
        sx={{
          marginBottom: 5,
        }}
      >
        <Divider />
      </Box>
      <SubscriptionActions
        setType={setType}
        openRenewPlanForm={openRenewPlanForm}
      />
      <Modal
        open={showRenewPlanForm}
        onClose={closeRenewPlanForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: 800,
            maxWidth: '90%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <RenewPlanForm type={type} handleClose={closeRenewPlanForm} />
        </Box>
      </Modal>
    </Paper>
  );
}
