import { useState } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import AdminDrawer from "../../components/navbar/AdminDrawer";
import AdminAppBar from "../../components/navbar/AdminAppBar";
import MobileAdminDrawer from "../../components/navbar/MobileAdminDrawer";
import { drawerWidth } from "../../components/navbar/utilAminNavbar";

function DashboardLayoutPage(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    // setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ display: { xs: "block", sm: "block", md: "flex" } }}>
      <AdminAppBar handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
        aria-label="admin drawer"
      >
        {/* mobile admin drawer, disappers in lg screen
        <MobileAdminDrawer
          mobileOpen={mobileOpen}
          handleDrawerTransitionEnd={handleDrawerTransitionEnd}
          handleDrawerClose={handleDrawerClose}
        /> */}
        {/* large screen drawer, disappears in sm and xs screens*/}
        <AdminDrawer handleDrawerClose={handleDrawerClose} />
      </Box>
      {/* mobile admin drawer, disappers in lg screen */}
      <MobileAdminDrawer
        mobileOpen={mobileOpen}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        handleDrawerClose={handleDrawerClose}
      />
      <Box
        component="main"
        sx={{
          flexGrow: { md: 1 },
          bgcolor: "background.default",
          width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Divider />
        <Box py={1}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayoutPage;
