import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

import InstructionItem from "./InstructionItem";
import { addUserStepsData } from "./stepsUtil";
import Button from "@mui/material/Button";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import CSVDownloader from "../access-control/CSVDownloader";

export default function AddUserInstructions() {
  return (
    <Paper
      sx={{
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: { xs: 1, sm: 4, md: 8 },
        paddingRight: { xs: 1, sm: 4, md: 8 },
        boxShadow: 8,
      }}
    >
      {/* <CardHeader
        avatar={<PermContactCalendarIcon />}
        title="Add user to users table"
        subheader="Refer to the following steps to get started."
      /> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          flexWrap: "nowrap",
          marginBottom: 2,
        }}
      >
        <PermContactCalendarIcon />
        <Typography variant="h6">Add user to users table </Typography>
      </Box>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          Refer to the following steps to get started.
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{ padding: 0.5 }}
          startIcon={<HelpCenterIcon />}
          color="primary"
        >
          <CSVDownloader />
        </Button>
      </Box>
      <Box>
        <List sx={{ maxWidth: 640 }}>
          {addUserStepsData.map((step, indx) => (
            <InstructionItem
              key={step.title}
              number={indx + 1}
              title={step.title}
              subtitle={step.subtitle}
              steps={step.steps}
            />
          ))}
        </List>
      </Box>
    </Paper>
  );
}
