import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Upload as UploadIcon } from "../icons/Upload";
import AddBedForm from "./BedsForm";
import { getWardsData } from "../wards/utilWards";

export const BedsToolbar = () => {
  const hospital = useSelector((state) => state.auth.hospital);
  const { isLoading, isError, wards } = useSelector((state) => state.wards);
  useEffect(() => {
    getWardsData(hospital?._id);
    return () => {};
  }, [hospital]);
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        marginTop: 2,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: { xs: 4, sm: 2, md: 4 },
        paddingRight: { xs: 4, sm: 2, md: 4 },
        textAlign: { xs: "center", sm: "left" },
      }}
    >
      <Box mb={5}>
        <Typography variant="h6">Add one bed at a time</Typography>
        <Typography variant="subtitle2" color="text.secondary">
          In each ward, add beds one by one. At ward level, the bed name has to
          be uniquie. There could be same bed name but in different wards. Only
          wards created in wards section will show in wards dropdown. If you
          dont see a ward in the dropdown, please create that in wards section
          first.
        </Typography>
      </Box>
      <Box sx={{ minHeight: 40 }}>
        {!isLoading && !isError && <AddBedForm wards={wards} />}
      </Box>
    </Paper>
  );
};

export default BedsToolbar;
