import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import departmentImg from "../../assets/department.png";
import departmentSelectImg from "../../assets/departmentSelectImg.png";
import departmentTableImg from "../../assets/departmentTableImg.png";
import wardsImg from "../../assets/wardsImg.png";
import addWardImg from "../../assets/addWardImg.png";
import wardsTableImg from "../../assets/wardsTableImg.png";
import addBedsImg from "../../assets/addBedsImg.png";
import accessControlImg from "../../assets/accessControlImg.png";
import addSingleUserImg from "../../assets/addSingleUserImg.png";
import accessControlTableImg from "../../assets/accessControlTableImg.png";

const stepStyle = {
  display: "flex",
  flexDirection: "column",
};

const stepItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: 2,
};

const imgStyle = {
  width: "100%",
  maxWidth: "100%",
};

const departmentSteps = (
  <>
    <Typography mb={1} variant="subtitle2">
      Steps:
    </Typography>
    <Box sx={stepStyle}>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">Go to left side menu</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click on the 'Hospital'</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click on 'Departments'</Typography>
        <ArrowRightAltIcon />{" "}
      </Box>
      <Box sx={{ height: "400px", objectFit: "contain", margin: "32px 10px" }}>
        <img style={imgStyle} alt="Select Img" src={departmentImg} />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          then click on select Departments dropdown
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          choose all departments available in the hospital
        </Typography>{" "}
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click +Add button </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Typography variant="subtitle2">
        added departments will be shown in table
      </Typography>
      <Box sx={{ height: "360px", objectFit: "contain", margin: "32px 0" }}>
        <img
          style={imgStyle}
          alt="Select Department img"
          src={departmentSelectImg}
        />
      </Box>
      <Box>
        <Typography mb={2} variant="h6">
          Edit & Delete Department list
        </Typography>
        <Typography mb={2}>
          One can add departments or remove departments from the list using
          respective icons in the department table.
        </Typography>
        <Typography>
          Please note that, once a user is assigned to a department, that
          department can not be deleted. One has to reassign all the users to a
          different department or delete all those users before deleting that
          department.
        </Typography>
        <Typography my={2} variant="h6">
          A Department name is different in your hospital than what's given.
        </Typography>
        <Typography>
          If a department's name is different in your hospital compared to whats
          given in the default list, one can edit the name of the department.
          Ex: "Biomedical Engineering" is on of the department in the default
          list provided. Let's assume it's called "Biomedical Department" in
          your hospital. First choose "Biomedical Engineering" and save it to
          the table. Now click on edit button and change it's name to
          "Biomedical Department" and click save. Please note that you can not
          re add "Biomedical Engineering" from the default list again.
        </Typography>
        <Typography>
          By this technique, one can rename any department to whatever name, but
          we discourage doing that as it will be hard to keep track. It will be
          difficult to make changes in the future or add new departments or
          delete departments. We recommend you to rename a department to to it's
          releted name only.
        </Typography>
      </Box>
      <Box sx={{ height: "150px", objectFit: "contain", margin: "32px 0" }}>
        <img
          style={imgStyle}
          alt="Select Department img"
          src={departmentTableImg}
        />
      </Box>
    </Box>
  </>
);

const wardsStep = (
  <>
    <Typography mb={1} variant="subtitle2">
      Steps:
    </Typography>
    <Box sx={stepStyle}>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">Go to left side menu</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click on the 'Hospital'</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click on 'Wards'</Typography>
        <ArrowRightAltIcon />{" "}
      </Box>
      <Box sx={{ height: "200px", objectFit: "contain", margin: "32px 0" }}>
        <img style={imgStyle} alt="Select ward img" src={wardsImg} />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          click on Ward input area & type name of the ward
        </Typography>
        <ArrowRightAltIcon />
      </Box>

      <Box sx={{ height: "110px", objectFit: "contain", margin: "32px 0" }}>
        <img style={imgStyle} alt="Select ward img" src={addWardImg} />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click +Add button</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Added ward will be shown in table
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={{ height: "100px", objectFit: "contain", margin: "32px 0" }}>
        <img style={imgStyle} alt="Select ward img" src={wardsTableImg} />
      </Box>
      <Box>
        <Typography mb={2} variant="h6">
          Edit & Delete Wards
        </Typography>
        <Typography>
          Ward name can be edited using icons infront of the ward name in the
          table. Edited ward name will reflect in associated beds. Ward can be
          deleted using delete icon infront of its name in wards table. If a
          ward is deleted, associated beds will be deleted too. A ward can not
          be deleted if a patient is assigned to any bed in that ward. First
          remove all patient assignments from that ward, make all beds empty,
          then delete ward.
        </Typography>
      </Box>
    </Box>
  </>
);

const bedSteps = (
  <>
    <Typography mb={1} variant="subtitle2">
      Steps:
    </Typography>
    <Box sx={{ stepStyle }}>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">Go to left side menu</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click on the 'Hospital'</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click on 'Beds'</Typography>
        <ArrowRightAltIcon />{" "}
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          choose a Ward from the dropdown
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          enter a bed name in input box
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={{ height: "160px", objectFit: "contain", margin: "32px 0" }}>
        <img style={imgStyle} alt="Add bed img" src={addBedsImg} />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click +Add button</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          added bed and it's ward will be shown in table
        </Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography mb={2} variant="h6">
          Edit & Delete Beds
        </Typography>
        <Typography>
          Added bed can be edited and deleted from the table using edit and
          delete icons infront of the bed in the table. If a patient is assigned
          to a bed, that bed can not be deleted. First remove the patient
          assignment for the bed using Daily Doc app. Only an empty bed can be
          deleted.
        </Typography>
      </Box>
    </Box>
  </>
);

const accessControlSteps = (
  <>
    <Typography mb={1} variant="subtitle2">
      Steps:
    </Typography>
    <Box sx={stepStyle}>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">Go to left side menu</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click on the 'Users'</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box
        sx={{
          height: "240px",
          objectFit: "contain",
          marginTop: "32px",
          marginBottom: 6,
        }}
      >
        <img style={imgStyle} alt="Select ward img" src={accessControlImg} />
      </Box>
      <Typography mb={2} variant="h6">
        Add one user at a time
      </Typography>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Go to "Single Upload" section. Enter country code without plus sign
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Add user's mobile number in input box
        </Typography>
        <ArrowRightAltIcon />
      </Box>

      <Box sx={{ height: "140px", objectFit: "contain", margin: "32px 0" }}>
        <img style={imgStyle} alt="Select ward img" src={addSingleUserImg} />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">click "+ Add" button</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          added user's number and it's country code will be shown in table
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={{ height: "142px", objectFit: "contain", margin: "32px 0" }}>
        <img
          style={imgStyle}
          alt="Select ward img"
          src={accessControlTableImg}
        />
      </Box>
      <Typography mb={2} variant="h6">
        Edit and Delete Mobile numbers in user table
      </Typography>
      <Typography variant="subtitle2">
        A mobile number added to user table can be edited or deleted before the
        user registers for Daily Doc app. A registered mobile number can not be
        edited as it's verified. However, it can be deleted any time. A
        registered mobile no will have a check mark in the table. If a
        registered mobile number is deleted from user table, the user with that
        mobile number will loose access to Daily Doc app. If that user is
        secretary or subadmin, he will loose access to admin panel as well.
      </Typography>

      <Typography my={2} variant="h6">
        Bulk upload using CSV
      </Typography>
      <Typography variant="subtitle2">
        See seperate instructions on how to bulkupload user mobile numbers using
        csv file.
      </Typography>
    </Box>
  </>
);

const downloadDdSteps = (
  <>
    <Typography mb={1} variant="subtitle2">
      Steps:
    </Typography>
    <Box sx={stepStyle}>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          download android or iOS app from app store
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">enter OTP sent on mobile</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">choose the hospital</Typography>
        <ArrowRightAltIcon />{" "}
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          enter first name, last name, department, role and designation
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">start using Daily Doc app</Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Once the user onboards in Daily Doc app, the users data will show in
          Users table.
        </Typography>
      </Box>
    </Box>
    <Typography mb={1} variant="subtitle2">
      Admin, subadmin or department secretary can verify user datails, change it
      as needed.
    </Typography>
    <Typography variant="subtitle2">
      Admin, subadmin or department secretary can suspend, delete users or
      modify their datails as needed.
    </Typography>
  </>
);

export const welcomeStepsData = [
  // {
  //   title: "Rapid onboarding",
  //   subtitle:
  //     "Daily Doc minimizes data entry. ***  Before users start using Daily Doc mobile app, admin needs to add all deparments, wards, beds available in the hospital. This is very important!!",
  //   steps: null,
  // },
  {
    title: "Add departments",
    subtitle:
      "Before anyone starts using Daily Doc app, add all departments present in your hospital. A user won't be able pick his department if it's not added in admin panel. Users can not onboard in Daily Doc app if departments not added. Every hospital has different department list, so curate your list first.",
    steps: departmentSteps,
  },
  {
    title: "Add ward or floor names",
    subtitle:
      "Wards and beds are needed to add patients and to create patient's care team. Add exact names of wards or floors available in your hospital. Name will appear as it is typed. Capitalize initial letter if desired. One can not add patients in Daily Doc mobile app withought creating wards and beds infrastructure.",
    steps: wardsStep,
  },
  {
    title: "Add beds",
    subtitle:
      "Add all beds available in each ward. Bed name needs to be unique for a given ward or floor. One can not add two beds of same name in given ward or floor. One could have same bed name in different wards or floors.",
    steps: bedSteps,
  },
  {
    title: "Add user mobile number to user table",
    subtitle:
      "To grant access to a user for Daily Doc mobile app, one has to add user's mobile number with country code in the user table. No need to add users name, role, designation or department. That data will be added by user during onboardin in mobile app. Only users whose mobile number is added in user table will have access to Daily Doc mobile app. Admin, subadmin, secretary are automatically added to users table and will have access to Daily Doc app.",
    steps: accessControlSteps,
  },
  {
    title: "Last step, have users download mobile app and register",
    subtitle:
      "After downloading Daily Doc mobile app on the cell phone (iOS or Android) from respective app store, the user/s will register through the app. Only users whose mobile number is added in User table will be able to access Daily Doc app. During the onboarding process, users are asked to enter name, pick role, choose department and designation.  Once user is registered, it's information will show up in the User table. It can be checked for accuracy by department scretary, subadmin or admin and modified as needed.",
    steps: downloadDdSteps,
  },
];

const addManyUsersSteps = (
  <>
    <Typography mb={1} variant="subtitle2">
      Steps:
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: 2,
      }}
    >
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          From the left sided menu select option 'Users'.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Download csv template by clicking on "Download Sample CSV" button in
          right upper corner. In windows bases laptop or desktop, open
          downloaded sample csv using microsoft excel. In Mac laptops or
          desktops open the downloaded csv file using microsoft excel or apple's
          Numbers.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          In sample csv, the first row has column headings. Keep those. Dont
          change or delete them. Second row has example data. Delete second row
          data.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          New data can be added from second row and below. In first column, add
          country code without plus sign. One has to add country code for every
          mobile number in each row.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          In second column add complete mobile number without any spaces, or
          dashes or brackets or any special characters.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Make sure there is a mobile number in front of every country code and
          a country code behing every mobile number. Remove any extra spaces.
          Correct errors. Remove unwanted entries. When done entering all mobile
          numbers and correcting all errors, save the file as a csv file. From
          excel sheet, click file, click save as, give name to file, choose file
          type as csv and then save it. If using apples Numbers, click file,
          click export to, choose csv, dont check include table name's box,
          click next, give a name, choose location to store, click next. Your
          file is now ready for upload.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          From the left sided menu select option 'Users'. Click on 'Upload CSV'
          button. A seperate page will open up
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Click on 'Browse CSV Files' button. Browse to above saved CSV file and
          click on it.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Click on 'open'. CSV Data will be uploaded in the table.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          Look for any errors in errors column. Using edit icon, one can correct
          errors. One can delete unwanted rows using delete button.
        </Typography>
        <ArrowRightAltIcon />
      </Box>

      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          When all errors are resolved, your data is ready for upload. Click on
          'Upload Data' button in right upper corner of the table.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          All valid mobile numbers will be added to Users table. Invalid numbers
          will be returned and showed with errors message. Duplicate numbers
          will not be added.
        </Typography>
        <ArrowRightAltIcon />
      </Box>
      <Box sx={stepItemStyle}>
        <Typography variant="subtitle2">
          All accepted mobile number will be shown in Users table.
        </Typography>
      </Box>
      <Typography mb={2} variant="h6">
        Edit and Delete Mobile numbers in Users table
      </Typography>
      <Typography variant="subtitle2">See section below single</Typography>
    </Box>
  </>
);

export const addUserStepsData = [
  {
    title: "Add multiple users at once with csv",
    subtitle:
      "One can add multiple users with a csv file at once to rapidly onboard users. Make sure the csv data is clean and preperated properly for uploading. Use only csv file.",
    steps: addManyUsersSteps,
  },
  {
    title: "Add single user",
    subtitle:
      "Daily Doc minimizes data entry by hospital admins. However admin has to do a one time set up of the hospital departments, wards and beds through this admin panel. Daily Doc mobile app users ie hospital employees register through mobile app and add users data such as first name, last name, their department, role and designation. *** It's import to set up your hospital through admin panel before users start using Daily Doc mobile app***.The hospital roles (Ex: Doctor, Nurse, Pharmacist etc) and designations (Ex:Attending Physician, Department chair, Staff Nurse) are predefined for users to pick while onboarding. However, admin needs to add departments, wards and beds available in the hospital as they are unique for each hospital.",
    steps: accessControlSteps,
  },
];
