import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAction } from "../../redux/auth/authAction";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import Button from "@mui/material/Button";
import api from "../../utilities/api";
import setAuthToken from "../../utilities/setAuthToken";
import Modal from "@mui/material/Modal";
import HPlusMobiledataIcon from "@mui/icons-material/HPlusMobiledata";

import {
  loginFailureAction,
  loginSuccessAction,
} from "../../redux/auth/authAction";
import { Paper } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function SelectHospital() {
  const auth = useSelector((state) => state.auth);
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(logoutAction());
    setOpen(false);
    navigate("/", {});
  };

  const { hospitalProfiles, user } = auth;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Paper
          sx={{
            minWidth: 300,
          }}
        >
          <Box
            sx={{
              backgroundColor: "primary.main",
              height: 70,
              display: "flex",
              alignItems: "center",
              padding: 2,
              justifyContent: "center",
            }}
          >
            <Typography color="#fff" variant="h6">
              Select Hospital
            </Typography>
          </Box>
          <Box>
            <List>
              {hospitalProfiles.map((item, indx) => (
                <ListItem key={indx}>
                  <ListItemButton
                    onClick={async () => {
                      const data = {
                        userDetails: {
                          user: user?._id,
                          hospitalId: item.hospital._id,
                          type: "web",
                        },
                      };
                      if (item.hospital.profile.webOnboarded) {
                        try {
                          const response = await api.post("/auth/user", data);
                          if (response) {
                            const { data } = response.data;
                            setAuthToken(data.token);
                            dispatch(loginSuccessAction(data));
                            navigate("/dashboard");
                          }
                        } catch (err) {
                          if (err) {
                            dispatch(loginFailureAction());
                          }
                        }
                      }
                      if (!item.hospital.profile.webOnboarded) {
                        // get token for onboarding
                        // may have to make API call again to do that.
                        // then redirect to edit onboarding
                        // dispatch(loadObUserAction(response.data.data.user));
                        // for now we will just direct to onboarding
                        navigate("/edit-onboarding");
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: "secondary.light",
                        }}
                      >
                        <Typography>
                          {item?.hospital?.name?.charAt(0)?.toUpperCase()}
                        </Typography>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>{item.hospital.name}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                dispatch(logoutAction());
                setOpen(false);
                navigate("/", {});
              }}
              startIcon={<KeyboardBackspaceOutlinedIcon />}
            >
              Cancel
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}

export default SelectHospital;
