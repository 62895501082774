import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function EditObWard({
  open,
  onClose,
  formik,
  rowToEdit,
  setRowToEdit,
  wardErr,
  setWardErr,
}) {
  const editWard = () => {
    if (!formik.values.ward?.length > 0) {
      setWardErr("Ward name can not be empty");
      return;
    }
    let match = formik.values.wards?.find(
      (item) => item?.name === formik.values.ward && item.id !== rowToEdit.id
    );
    if (match) {
      setWardErr("Can not add duplicate ward.");
      return;
    }
    const newWards = formik.values.wards.map((item) =>
      item.id === rowToEdit.id ? { ...item, name: formik.values.ward } : item
    );
    formik.setFieldValue("wards", newWards);
    formik.setFieldValue("ward", "");
    onClose();
  };
  useEffect(() => {
    return () => {
      formik.setFieldValue("ward", "");
      setRowToEdit([]);
    };
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth={"xs"} open={open} onClose={onClose}>
        <DialogTitle>Edit Ward</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 2 }}
            //   size="small"
            fullWidth
            id="ward"
            name="ward"
            label="Ward name"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.ward}
            onChange={(e) => {
              formik.handleChange(e);
              if (e.target.value?.length > 0) {
                setWardErr("");
              }
            }}
            error={formik.touched.ward && Boolean(formik.errors.ward)}
            helperText={formik.touched.ward && formik.errors.ward}
          />
          {
            <Box sx={{ color: "error.main", paddingLeft: 1, mt: 1 }}>
              {wardErr}
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Box sx={{ position: "relative" }}>
            <Button
              // variant='contained'
              fullWidth
              type="button"
              disabled={formik.isSubmitting}
              onClick={() => editWard()}
            >
              Save
            </Button>
            {formik.isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
