// import { useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import FormHelperText from '@mui/material/FormHelperText';
// import { getPlansAndFeatures } from "./util";
import { useSelector } from 'react-redux';

export default function PlanOptions({ formik, type }) {
  // useEffect(() => {
  //   getPlansAndFeatures();
  // }, []);
  const sub = useSelector((state) => state.subscription.hospital);
  let { plans } = useSelector((state) => state.subscription);
  if (sub?.subscription?.plan?._id && type === 'CHANGE_PLAN') {
    plans = plans?.filter(
      (plan) => plan?._id.toString() !== sub?.subscription?.plan?._id.toString()
    );
  }
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          flexWrap: 'nowrap',
          marginBottom: 5,
        }}
      >
        <RunCircleIcon sx={{ fontSize: 40 }} color="secondary" />
        <Typography variant="h6">
          {type === 'ADD_USER' ? 'Add Users' : 'Select Plan & Users'}
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <FormControl
          fullWidth
          error={Boolean(formik.touched.plan && formik.errors.plan)}
        >
          <InputLabel id="select plan">Select Plan</InputLabel>
          <Select
            name="plan"
            labelId="select plan"
            id="plan-simple-select"
            value={formik.values.plan}
            label="Select Plan"
            onChange={formik.handleChange}
          >
            {plans.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.title}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.plan && formik.errors.plan && (
            <FormHelperText>{formik.errors.plan}</FormHelperText>
          )}
        </FormControl>
      </Box>
      {type !== 'ADD_USER' && (
        <Box
          sx={{
            mb: 3,
          }}
        >
          <FormControl
            fullWidth
            error={Boolean(formik.touched.years && formik.errors.years)}
          >
            <InputLabel id="select duration">
              Select Subscription Duration
            </InputLabel>
            <Select
              name="years"
              labelId="select duration"
              id="duration-simple-select"
              value={formik.values.years}
              label="Select Duration"
              onChange={formik.handleChange}
            >
              <MenuItem value={1}>1 Year</MenuItem>
              <MenuItem value={2}>2 Year</MenuItem>
              <MenuItem value={3}>3 Year</MenuItem>
            </Select>
            {formik.touched.years && formik.errors.years && (
              <FormHelperText>{formik.errors.years}</FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
      <TextField
        type="number"
        name="users"
        autoComplete="off"
        fullWidth
        label={
          type == 'ADD_USER' ? 'Add additional users' : 'Select number of users'
        }
        value={formik.values.users}
        onChange={formik.handleChange}
        error={formik.touched.users && Boolean(formik.errors.users)}
        helperText={formik.touched.users && formik.errors.users}
      />{' '}
    </Box>
  );
}
