import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Timer from "../timer/Timer";

function EmailOtp({ formik, resetEmail, resendEmailOtp }) {
  return (
    <Stack spacing={{ xs: 2, sm: 4 }}>
      <Box
        sx={{
          border: "1px solid #E6E8F0",
          height: 56,
          borderRadius: 1,
          backgroundColor: "background.default",
          display: "flex",
          alignItems: "center",
          padding: 2,
          // paddingRight: 3.5,
        }}
      >
        <Typography sx={{ flexGrow: 1 }}>{formik?.values?.phone}</Typography>
        <DoneIcon color="primary" />
      </Box>
      <Box
        sx={{
          border: "1px solid #E6E8F0",
          height: 56,
          borderRadius: 1,
          backgroundColor: "background.default",
          display: "flex",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography sx={{ flexGrow: 1 }}>{formik?.values?.email}</Typography>
        <IconButton sx={{ mx: 1 }} onClick={resetEmail}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <TextField
        fullWidth
        id="emailOtp"
        name="emailOtp"
        label="Email OTP"
        value={formik.values.emailOtp}
        onChange={formik.handleChange}
        error={formik.touched.emailOtp && Boolean(formik.errors.emailOtp)}
        helperText={formik.touched.emailOtp && formik.errors.emailOtp}
        inputProps={{ maxLength: 6 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOpenIcon />
            </InputAdornment>
          ),
        }}
      />
      <Timer resendOtp={resendEmailOtp} />
    </Stack>
  );
}

export default EmailOtp;
