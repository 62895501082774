import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MobileEmailOtp = (props) => {
  const { formik, backStep, currentStep } = props;
  return (
    <>
      <Box
        sx={{ display: "flex", marginBottom: 2 }}
        // onClick={() => backStep(currentStep)}
      >
        <Typography color="#757575">{formik.values?.me}</Typography>
        <KeyboardArrowDownIcon onClick={() => backStep(currentStep)} />
      </Box>
      <Typography color="#757575" mb={4} variant="body1">
        Enter one-time password (OTP) sent on above for verification
      </Typography>
      <TextField
        // size="small"
        name="otp"
        autoComplete="off"
        fullWidth
        label="Enter OTP"
        inputProps={{ maxLength: "6" }}
        value={formik.values.otp}
        onChange={formik.handleChange}
        error={formik.touched.me && Boolean(formik.errors.otp)}
        helperText={formik.touched.me && formik.errors.otp}
      />

      {/** using Material Ui ferro field so commenting ErrorMessage
        <ErrorMessage name='otp' component={TextError} />
        */}
    </>
  );
};

export default MobileEmailOtp;
