import api from "../../utilities/api";
import { loadAccessControlDataAction } from "../../redux/acess-control/AccessControlAction";
import { store } from "../../redux/store";
import { showSnackbarAction } from "../../redux/alert/alertAction";

export const getAccessControlData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/users?type=accesscontrol`;
  try {
    const response = await api.get(url);
    if (response)
      store.dispatch(loadAccessControlDataAction(response?.data?.data?.users));
  } catch (error) {
    store.dispatch(showSnackbarAction(error?.response?.data?.message, "error"));
  }
};
