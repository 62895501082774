import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function ExitDialog(props) {
  const { handleExit, closeDialog, open } = props;

  return (
    <>
      <Box>
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              If there is data in current step, it can not be saved. To save
              data from current step, click next and then exit.
            </DialogContentText>
            <Box sx={{ marginTop: 2 }}>
              <DialogContentText>
                Are you sure you want to exit anyway?
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button onClick={handleExit} autoFocus>
              Exit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default ExitDialog;
