import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

function ZeroPage(props) {
  const { formik } = props;
  const { openOtp } = props;
  return (
    <div>
      <TextField
        fullWidth
        id='mobile'
        name='mobile'
        label='mobile'
        value={formik.values.mobile}
        onChange={formik.handleChange}
        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
        helperText={formik.touched.mobile && formik.errors.mobile}
      />

      <Box
        sx={{
          ...(!openOtp && { display: 'none' }),
        }}
      >
        <TextField
          fullWidth
          id='mobileOtp'
          name='mobileOtp'
          label='mobile OTP'
          type='password'
          value={formik.values.mobileOtp}
          onChange={formik.handleChange}
          error={formik.touched.mobileOtp && Boolean(formik.errors.mobileOtp)}
          helperText={formik.touched.mobileOtp && formik.errors.mobileOtp}
        />
      </Box>
    </div>
  );
}

export default ZeroPage;
