import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Box from "@mui/system/Box";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

function Schedules() {
  return (
    <Container maxWidth="lg">
      <Stack sx={{ textAlign: "center", marginTop: 16 }}>
        <Box>
          <TimerOutlinedIcon fontSize="large" color="disabled" />
          <Typography
            variant="h2"
            sx={{
              color: "lightgray",
              fontSize: {
                xs: "2rem", // For extra small screens (mobile)
                sm: "3rem", // h2 default size for all other screens
              },
            }}
          >
            Coming soon !
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}

export default Schedules;
