import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

import InstructionItem from "./InstructionItem";
import { welcomeStepsData } from "./stepsUtil";

export default function GettingStarted() {
  return (
    <Paper
      sx={{
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: { xs: 1, sm: 3, md: 5 },
        paddingRight: { xs: 1, sm: 3, md: 5 },
        boxShadow: 8,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          flexWrap: "nowrap",
          marginBottom: 1,
        }}
      >
        <DirectionsRunIcon />
        <Typography variant="h6">Get started. Onetime setup!!.</Typography>
      </Box>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          *First add departments, wards and beds available in your hospital
          before anyone using Daily Doc app!!
        </Typography>
      </Box>
      <Box>
        <List sx={{ maxWidth: 640 }}>
          {welcomeStepsData.map((step, indx) => (
            <InstructionItem
              key={step.title}
              number={indx + 1}
              title={step.title}
              subtitle={step.subtitle}
              steps={step.steps}
            />
          ))}
        </List>
      </Box>
    </Paper>
  );
}
