import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import { Button, Divider } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

export default function CalculatePrice({
  formik,
  priceData,
  applyPromocode,
  type,
}) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          flexWrap: 'nowrap',
          marginBottom: 2,
        }}
      >
        <DoneIcon sx={{ fontSize: 24 }} color="secondary" />
        <Typography variant="h6">{priceData?.planName}</Typography>
      </Box>
      <Box mb={3}>
        <Divider />
      </Box>
      <FormControl fullWidth>
        <Box>
          <Grid
            sx={{
              marginBottom: 3,
              paddingLeft: 2,
            }}
            container
            rowSpacing={{ xs: 0, sm: 0.5 }}
            columnSpacing={2}
          >
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
                variant="subtitle2"
              >
                Price/user:
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant="subtitle2">
                {priceData?.currency} {priceData?.pricePersUer}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
                variant="subtitle2"
              >
                Users:
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant="subtitle2">
                {priceData?.usersCount}
              </Typography>
            </Grid>
            {type === 'ADD_USER' && (
              <>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                    }}
                    variant="subtitle2"
                  >
                    Duration:
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Typography variant="subtitle2">
                    {`${priceData?.remainingDays} days`}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
                variant="subtitle2"
              >
                Price:
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant="subtitle2">
                {priceData?.currency} {priceData?.totalUserBasePrice}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
                variant="subtitle2"
              >
                Discount:
              </Typography>
            </Grid>

            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant="subtitle2">
                {priceData?.currency} {priceData?.discountAmount}
              </Typography>
            </Grid>

            {priceData?.promocodeDiscountAmount > 0 && (
              <>
                {' '}
                <Grid size={{ xs: 12, sm: 4 }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                    }}
                    variant="subtitle2"
                  >
                    Promo Code Discount:
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Typography variant="subtitle2">
                    {priceData?.currency} {priceData?.promocodeDiscountAmount}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
                variant="subtitle2"
              >
                Tax:
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant="subtitle2">
                {priceData?.currency} {priceData?.serviceCharge}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
                variant="subtitle2"
              >
                Total Price:
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant="subtitle2">
                {priceData?.currency} {priceData?.price}
              </Typography>
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid size={{ xs: 12, sm: 7 }}>
              <TextField
                name="promoCode"
                autoComplete="off"
                fullWidth
                label="Use promo code"
                value={formik.values.promoCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.promoCode && Boolean(formik.errors.promoCode)
                }
                helperText={formik.touched.promoCode && formik.errors.promoCode}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 7 }}>
              <Button
                onClick={() => applyPromocode()}
                type="button"
                variant="text"
              >
                Apply Promocode
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FormControl>
    </Box>
  );
}
