import axios from 'axios';
import { store } from '../redux/store';
import { LOGOUT } from '../redux/auth/authTypes';

const api = axios.create({
  baseURL: 'https://api.dailydoc.io/api',
  // baseURL: 'http://localhost:3020/api',
  // baseURL:
  //   "https://ff05-2405-201-a415-116f-2dc6-64ec-976a-ef55.in.ngrok.io/api",
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'any',
  },
});

// Add a request interceptor to add the JWT token to the headers
api.interceptors.request.use(
  (config) => {
    // Get the JWT token from localStorage, sessionStorage, or state
    const token = localStorage.getItem('token'); // Adjust based on where your token is stored
    if (token) {
      // Add the token to the Authorization header
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error) => {
    // Handle error if needed
    return Promise?.reject(error);
  }
);

// api.interceptors.request.use(
//   async (config) => {
//     try {
//       // Get the token from AsyncStorage
//       const token = localStorage.getItem("token");

//       if (token) {
//         // Attach the token to the Authorization header
//         config.headers["x-access-token"] = token;
//       }
//     } catch (error) {
//       // console.error("Error retrieving token:", error);
//     }
//     return config;
//   },
//   (error) => {
//     // Handle errors during request setup
//     return Promise.reject(error);
//   }
// );

/**
 interceptor funciton is run async by default after then and cath.
 following interceptor function runs after a response is received. 
 there is a seperate interceptor function if one wants do something before making a request. 
 in the folliwing function, if one gets a response, we will return the response.
 if one gets an error, we will.
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired
 logout the user if the token has expired
**/

// api.interceptors.response.use(
//   (res) => res,
//   async (err) => {
//     if (err?.response?.data?.message === "Not authorized") {
//       store.dispatch({ type: LOGOUT });
//     }
//     if (err?.message === "Network Error") {
//       store.dispatch(showSnackbarAction("Network Error", "error"));
//     } else if (err?.code === "ECONNABORTED") {
//       store.dispatch(showSnackbarAction("Server not responding", "error"));
//     }
//     return Promise.reject(err);
//   }
// );

// api.interceptors.response.use(
//   (res) => res,
//   async (err) => {
//     // Lets handle err
//     if (err?.response) {
//       // The server responded with a status code outside 2xx
//       if (err.response.data?.message === "Not authorized") {
//         store.dispatch({ type: LOGOUT });
//       }
//     }
//     // Handle Network Error
//     if (err?.message === "Network Error") {
//       store.dispatch(showSnackbarAction("Network Error", "error"));
//     }
//     // Handle Request Timeout
//     else if (err?.code === "ECONNABORTED") {
//       store.dispatch(showSnackbarAction("Server not responding", "error"));
//     }
//     // Catch other types of errors, e.g., unexpected errors
//     else {
//       store.dispatch(
//         showSnackbarAction("An unexpected error occurred", "error")
//       );
//     }
//     // Return the rejected promise to propagate the error further
//     return Promise.reject(err);
//   }
// );

api.interceptors.response.use(
  // If the request is successful, simply return the response
  (res) => res,
  (err) => {
    if (err.response.data.message === 'Not authorized') {
      store.dispatch({ type: LOGOUT });
    }
    // For any other error, return a rejected promise as usual
    return Promise.reject(err);
  }
);

export default api;
