import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Checkbox,
  Autocomplete,
  Stack,
  Typography,
} from "@mui/material";
import { borderRadius, Box, maxWidth, styled, width } from "@mui/system";
import { useFormik } from "formik";

import AddIcon from "@mui/icons-material/Add";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { loadAllAdminsAction } from "../../redux/admins/adminAction";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { getDepartmentsData } from "../departments/utilDepartments";
import api from "../../utilities/api";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledForm = styled("form")({
  width: "100%",
  maxWidth: "100%",
});

const style = {
  width: 800,
  maxWidth: "90%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  px: { xs: 1, sm: 2, md: 4 },
  py: 8,
};

let validationSchema;

const EditAdminForm = ({ rowToEdit, handleClose }) => {
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const { departmentsList } = useSelector((state) => state.departments);
  const { adminPanelRolesList } = useSelector((state) => state.list);

  const onSubmit = async (values) => {
    const formData = {
      user: {
        aclRole: values.adminPanelRole._id,
        departments: values.departments,
      },
    };

    try {
      let response = await api.patch(
        `hospitals/${hospital._id}/access/users/${rowToEdit._id}`,
        formData
      );
      dispatch(loadAllAdminsAction(response.data.data.users));
      dispatch(showSnackbarAction(response.data.message, "success"));
      handleClose();
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const initialValues = {
    adminPanelRole:
      adminPanelRolesList?.find((e) => e?.name === rowToEdit?.role) || null,
    departments: rowToEdit?.departments || [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    getDepartmentsData(hospital?._id);
  }, [hospital?._id]);

  return (
    <Box sx={style}>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 24,
          }}
          adminPanelRole
        >
          <SupervisorAccountIcon
            sx={{ fontSize: 40, marginRight: 2 }}
            color="secondary"
          />
          <Typography variant="h6">Edit Admin</Typography>
        </div>
        <Stack spacing={4}>
          <Autocomplete
            disabled={rowToEdit?.role === "Admin"}
            value={formik.values.adminPanelRole}
            id="adminPanelRole"
            name="adminPanelRole"
            options={adminPanelRolesList || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.name}
            onChange={(event, newValue) => {
              formik.setFieldValue("adminPanelRole", newValue);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Role"
                placeholder="Role"
                error={
                  formik.touched.adminPanelRole &&
                  Boolean(formik.errors.adminPanelRole)
                }
                helperText={
                  formik.touched.adminPanelRole && formik.errors.adminPanelRole
                }
              />
            )}
          />

          <Autocomplete
            multiple
            disabled={rowToEdit?.role === "Admin"}
            value={formik.values.departments}
            id="departments"
            name="departments"
            defaultValue={rowToEdit?.departments}
            options={departmentsList || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              formik.setFieldValue("departments", newValue);
            }}
            style={{
              display: rowToEdit?.role === "Admin" ? "none" : null,
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Departments"
                placeholder="Search Department"
                error={
                  formik.touched.departments &&
                  Boolean(formik.errors.departments)
                }
                helperText={
                  formik.touched.departments && formik.errors.departments
                }
              />
            )}
          />
          <Button
            sx={{ float: "right" }}
            type="submit"
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
        </Stack>
      </StyledForm>
    </Box>
  );
};

export default EditAdminForm;
