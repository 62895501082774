import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CountryAutocomplete from "../reusables/CountryAutocomplete";

import {
  removeStateListAction,
  removeCityListAction,
} from "../../redux/list/listAction";
import api from "../../utilities/api";

import { loadDefaultDepartmentsAction } from "../../redux/onboarding/onboardingAction";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import StateAutocomplete from "../reusables/StateAutocomplete";
import CityAutocomplete from "../reusables/CityAutocomplete";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { Box } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GridElement = styled(Grid)(({ theme }) => ({
  ".MuiTextField-root": { marginTop: "0" },
}));

export default function HospitalDetailsPage({ formik }) {
  // const [defaultDepartments, setDefaultDepartments] = useState([]);

  const defaultDepartments = useSelector(
    (state) => state.onboarding.defaultDepartments
  );

  const dispatch = useDispatch();

  const loadDefaultDepartments = () => {
    api
      .get("/onboarding/ob4/departments")
      .then((res) => {
        // setDefaultDepartments(res.data.data);
        dispatch(
          loadDefaultDepartmentsAction(res?.data?.data ? res?.data?.data : [])
        );
      })
      .catch((err) =>
        dispatch(showSnackbarAction(err?.response?.data?.message, "error"))
      );
  };

  useEffect(() => {
    loadDefaultDepartments();
    return () => {
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
    };
  }, []);

  return (
    <Box>
      <GridElement container rowSpacing={4} columnSpacing={{ sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="hospitalName"
            name="hospitalName"
            label="Hospital Name"
            value={formik.values.hospitalName}
            onChange={formik.handleChange}
            error={
              formik.touched.hospitalName && Boolean(formik.errors.hospitalName)
            }
            helperText={
              formik.touched.hospitalName && formik.errors.hospitalName
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="hospitalShortform"
            name="hospitalShortform"
            label="Hospital Name Shortform"
            value={formik.values.hospitalShortform}
            onChange={formik.handleChange}
            inputProps={{ maxLength: 6 }}
            error={
              formik.touched.hospitalShortform &&
              Boolean(formik.errors.hospitalShortform)
            }
            helperText={
              formik.touched.hospitalShortform &&
              formik.errors.hospitalShortform
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountryAutocomplete formik={formik} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StateAutocomplete
            formik={formik}
            countryId={formik.values.country?.countryId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CityAutocomplete
            formik={formik}
            stateId={formik.values.state?.stateId}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            value={formik.values?.departments}
            id="departments"
            name="departments"
            options={defaultDepartments ? defaultDepartments : []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              formik.setFieldValue("departments", newValue);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            //   style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Departments"
                placeholder="Search Department"
                error={
                  formik.touched.departments &&
                  Boolean(formik.errors.departments)
                }
                helperText={
                  formik.touched.departments && formik.errors.departments
                }
              />
            )}
          />
        </Grid>
      </GridElement>
    </Box>
  );
}
