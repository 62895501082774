import api from "../../utilities/api";
import { showSnackbarAction } from "../../redux/alert/alertAction";

import {
  loadEmployeesAction,
  loadDefaultEmployeeDataAction,
} from "../../redux/employees/employeesAction";
import { store } from "../../redux/store";

export const getEmployeeData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/users?type=onboarded`;

  try {
    const response = await api.get(url);
    if (response) store.dispatch(loadEmployeesAction(response.data.data.users));
  } catch (err) {
    store.dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
  }
};

export const getDefaultDataForEmployees = async (hospitalId) => {
  const url = `/common/hospitals/${hospitalId}/er1/employees`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(loadDefaultEmployeeDataAction(response.data.data));
    }
  } catch (err) {
    store.dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
  }
};
