import axios from "axios";
import { GEO_INFO_LOADED, DEFAULT_GEOINFO_LOADED } from "./geoInfoTypes";

export const loadGeoInfoAction = () => async (dispatch) => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    if (response)
      dispatch({
        type: GEO_INFO_LOADED,
        payload: response.data,
      });
  } catch (err) {
    dispatch({
      type: DEFAULT_GEOINFO_LOADED,
      payload: {
        country_code: "IN",
      },
    });
  }
};
