import { useEffect } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";

import DialogTitle from "@mui/material/DialogTitle";

import CircularProgress from "@mui/material/CircularProgress";
import { getDefaultDataForEmployees } from "./utilEmployeeTable";

const DialogElement = styled(Dialog)(({ theme }) => ({
  // '.MuiTextField-root': { marginTop: '0' },
  ".MuiGrid-root": { marginTop: 0 },
}));

export default function EditEmployeeForm({ open, onClose, formik }) {
  const hospital = useSelector((state) => state.auth.hospital);
  const { defaultRoles, defaultDesignations, hospitalDepartments } =
    useSelector((state) => state.employees);

  useEffect(() => {
    getDefaultDataForEmployees(hospital?._id);
    return () => {};
  }, [hospital]);

  return (
    <>
      <DialogElement open={open} onClose={onClose}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogTitle>Edit Employee</DialogTitle>
          <DialogContent>
            <Grid sx={{ marginTop: 4 }} container spacing={4}>
              {/* Use spacing for consistent layout */}
              <Grid size={6}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid size={6}>
                <Autocomplete
                  value={formik.values.role}
                  id="role"
                  name="role"
                  options={defaultRoles || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("role", newValue);
                  }}
                  inputValue={formik.values.inputRole}
                  onInputChange={(event, newInputValue) => {
                    formik.setFieldValue("inputRole", newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Role"
                      placeholder="Search role"
                      error={
                        formik.touched.inputRole &&
                        Boolean(formik.errors.inputRole)
                      }
                      helperText={
                        formik.touched.inputRole && formik.errors.inputRole
                      }
                    />
                  )}
                />
              </Grid>
              <Grid size={6}>
                <Autocomplete
                  id="department"
                  value={formik.values.department}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("department", newValue);
                  }}
                  inputValue={formik.values.inputDepartment}
                  onInputChange={(event, newInputValue) => {
                    formik.setFieldValue("inputDepartment", newInputValue);
                  }}
                  options={hospitalDepartments || []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      placeholder="Search department"
                      error={
                        formik.touched.inputDepartment &&
                        Boolean(formik.errors.inputDepartment)
                      }
                      helperText={
                        formik.touched.inputDepartment &&
                        formik.errors.inputDepartment
                      }
                    />
                  )}
                />
              </Grid>
              <Grid size={6}>
                <Autocomplete
                  value={formik.values.designation}
                  id="designation"
                  name="designation"
                  options={defaultDesignations || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("designation", newValue);
                  }}
                  inputValue={formik.values.inputDesignation}
                  onInputChange={(event, newInputValue) => {
                    formik.setFieldValue("inputDesignation", newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Designation"
                      placeholder="Search Designation"
                      error={
                        formik.touched.inputDesignation &&
                        Boolean(formik.errors.inputDesignation)
                      }
                      helperText={
                        formik.touched.inputDesignation &&
                        formik.errors.inputDesignation
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Box sx={{ position: "relative" }}>
              <Button
                // variant='contained'
                fullWidth
                type="submit"
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
              {formik.isSubmitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
          </DialogActions>
        </form>
      </DialogElement>
    </>
  );
}
