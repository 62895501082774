import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";

const DialogElement = styled(Dialog)(({ theme }) => ({
  //   ".MuiTextField-root": { marginTop: "0" },
  ".MuiGrid-root": { marginTop: 0 },
}));

export default function EditBed({ open, onClose, formik }) {
  const { wards } = useSelector((state) => state.wards);

  return (
    <DialogElement fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>Edit Bed</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Grid container spacing={{ sm: 2 }}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={formik.values.ward}
                name="ward"
                options={wards || []}
                getOptionLabel={(option) => option.name}
                // isOptionEqualToValue={(option, value) =>
                //   option.name === value.name
                // }
                onChange={(event, newValue) => {
                  formik.setFieldValue("ward", newValue);
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                inputValue={formik.values.inputWard}
                onInputChange={(event, newInputValue) => {
                  formik.setFieldValue("inputWard", newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Wards"
                    placeholder="Search Ward"
                    error={
                      formik.touched.inputWard &&
                      Boolean(formik.errors.inputWard)
                    }
                    helperText={
                      formik.touched.inputWard && formik.errors.inputWard
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Bed"
                id="bed"
                name="bed"
                value={formik.values.bed}
                onChange={formik.handleChange}
                error={formik.touched.bed && Boolean(formik.errors.bed)}
                helperText={formik.touched.bed && formik.errors.bed}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Box sx={{ position: "relative" }}>
            <Button
              // variant='contained'
              fullWidth
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
            {formik.isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </DialogActions>
      </form>
    </DialogElement>
  );
}
