import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import api from "../../utilities/api";
import { getDepartmentsData, getDefaultDepartments } from "./utilDepartments";
import { styled } from "@mui/system";

// for autocomplete

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Typography } from "@mui/material";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  departments: [],
};

const validation = yup.object().shape({
  departments: yup.array().min(1, "Choose at least one department").compact(),
});

function DepartmentsForm() {
  const hospital = useSelector((state) => state.auth.hospital);
  const { defaultDepartments } = useSelector((state) => state.departments);
  const { departmentsList } = useSelector((state) => state.departments);

  const unSelectedDepartments = defaultDepartments?.filter(
    (unSelectedOption) =>
      !departmentsList?.some(
        (selectionOption) => unSelectedOption?.name === selectionOption?.name
      )
  );

  useEffect(() => {
    getDefaultDepartments();
  }, [hospital]);

  const onSubmit = async (values, actions) => {
    const formData = {
      hospital: hospital._id,
      departments: values.departments,
    };
    try {
      const url = `/hospitals/${hospital._id}/departments`;
      const response = await api.post(url, formData);
      if (response) {
        getDepartmentsData(hospital._id);
        actions.resetForm({
          departments: [],
        });
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError("departments", err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  return (
    <>
      <Paper
        sx={{
          marginTop: 2,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: { xs: 4, sm: 2, md: 4 },
          paddingRight: { xs: 4, sm: 2, md: 4 },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Box mb={2}>
          <Typography variant="h6">Select Departments</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Select all departments availabe in the hospital
          </Typography>
        </Box>
        <Box>
          <StyledForm
            className="departments-form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Grid container spacing={{ xs: 1, sm: 2 }}>
              <Grid size={{ xs: 12, sm: "grow" }}>
                <Autocomplete
                  multiple
                  value={formik.values.departments}
                  id="departments"
                  name="departments"
                  // options={defaultDepartments}
                  options={unSelectedDepartments || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("departments", newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  //   style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Departments"
                      placeholder="Search Department"
                      error={
                        formik.touched.departments &&
                        Boolean(formik.errors.departments)
                      }
                      helperText={
                        formik.touched.departments && formik.errors.departments
                      }
                    />
                  )}
                />
                {/** 
                <TextField
                  // sx={{ marginRight: 1 }}
                  size='small'
                  fullWidth
                  id='departments'
                  name='departments'
                  label='Departments'
                  autoComplete='off'
                  autoCapitalize='off'
                  autoCorrect='off'
                  value={formik.values.departments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.departments &&
                    Boolean(formik.errors.departments)
                  }
                  helperText={
                    formik.touched.departments && formik.errors.departments
                  }
                />*/}
              </Grid>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Button
                  size="large"
                  sx={{ width: "100%" }}
                  // sx={{ float: "right" }}
                  type="submit"
                  startIcon={<AddIcon />}
                  color="primary"
                  variant="contained"
                  disabled={formik.isSubmitting}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </StyledForm>
        </Box>
      </Paper>
    </>
  );
}

export default DepartmentsForm;
