import api from "../../utilities/api";
import { loadWardsAction } from "../../redux/wards/wardsAction";
import { store } from "../../redux/store";
import { showSnackbarAction } from "../../redux/alert/alertAction";

export const getWardsData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/wards`;
  try {
    const response = await api.get(url);
    if (response) store.dispatch(loadWardsAction(response?.data?.data));
  } catch (err) {
    store.dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
  }
};
