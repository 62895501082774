import { Outlet } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import MainAppBar from "../../components/navbar/MainAppbar";
import Footer from "../../components/footer/Footer";

export default function MainLayoutPage() {
  return (
    <Container maxWidth="lg">
      {/** MainAppbar is top navbar, has fixed position to the top 0 and right 0 and left auto */}
      <MainAppBar />
      <Box
        component="main"
        sx={{
          bgcolor: "background.default",
          paddingBottom: 5,
          paddingTop: 5,
          minHeight: "100vh",
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Container>
  );
}
