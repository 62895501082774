import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { regex10DigitNumber, regex1To999 } from "./csvUtil";

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  px: 4,
  py: 8,
};

const StyledForm = styled("form")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  // '.MuiTextField-root': { marginTop: '0' },
  // '.MuiButton-root': { float: 'right' },
  ".MuiAutocomplete-root": { width: 200, marginRight: 16 },
  ".MuiTextField-root": { marginTop: 0, marginBottom: 0 },
});

//  for formik

const validationSchema = yup.object().shape({
  mobile: yup
    .string("Provide mobile number")
    .matches(regex10DigitNumber, "Provide valid mobile number")
    .required("Required"),
  countryCode: yup
    .string("Provide country code for mobile")
    .matches(regex1To999, "Provide valid country code")
    .required("Required"),
});

export default function EditCSVRow({ open, onClose, rowToEdit, saveRowData }) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      id: rowToEdit.id || "",
      countryCode: rowToEdit?.countryCode || "",
      mobile: rowToEdit?.mobile || "",
    },
    validationSchema: validationSchema,
    onSubmit: saveRowData,
    enableReinitialize: true,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <StyledForm className="employee-form" onSubmit={formik.handleSubmit}>
          <TextField
            sx={{ marginRight: 2 }}
            size="small"
            // fullWidth
            id="countryCode"
            name="countryCode"
            label="Country Code"
            value={formik.values.countryCode}
            onChange={formik.handleChange}
            error={
              formik.touched.countryCode && Boolean(formik.errors.countryCode)
            }
            helperText={formik.touched.countryCode && formik.errors.countryCode}
          />
          <TextField
            sx={{ marginRight: 2 }}
            size="small"
            // fullWidth
            id="mobile"
            name="mobile"
            label="Mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
          />
          <Button
            type="submit"
            // startIcon={<AddIcon />}
            color="primary"
            variant="contained"
          >
            Save row data
          </Button>
        </StyledForm>
      </Box>
    </Modal>
  );
}
