import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import CardHeader from "@mui/material/CardHeader";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { regex1000To15x9 } from "../../utilities/commonUtil";
import api from "../../utilities/api";

import { showSnackbarAction } from "../../redux/alert/alertAction";
import Password from "./Password";
import Otp from "./Otp";
import NewAdminDetails from "./NewAdminDetails";
import { countries } from "../../utilities/countries";

const StyledForm = styled("form")({
  width: "100%",
});

const pages = ["Enter password", "Enter OTP", "Enter new admin details"];

const passwordValidation = yup.object({
  password: yup.string("Enter password").required("Required"),
});

const otpValidation = yup.object({
  otp: yup.string("Enter OTP").required("Required"),
});

const adminDetailsValidation = yup.object({
  firstName: yup.string("Enter First Name").required("Required"),
  lastName: yup.string("Enter Last Name").required("Required"),
  email: yup.string("Enter email").email().required("Required"),
  country: yup
    .object()
    .shape({
      label: yup.string("Enter Country for mobile").required("Required"),
    })
    .typeError("Choose a country"),
  mobile: yup
    .string()
    .matches(regex1000To15x9, "Eenter valid mobile number.")
    .required("Required"),
});

const validationSchema = [
  passwordValidation,
  otpValidation,
  adminDetailsValidation,
];

export default function TransferAccount({ handleClose, setMyHospital }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [otpId, setOtpId] = useState("");
  const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.auth.hospital);
  const user = useSelector((state) => state.auth.user);
  const geoInfo = useSelector((state) => state.geoInfo?.geoInfo);

  const [defaultCountry, setdefaultCountry] = useState(
    countries.find((obj) => obj.code === geoInfo?.country_code) ||
      countries[103]
  );

  const isLastPage = currentStep === pages.length - 1;
  // const backStep = (step) => {
  //   setCurrentStep(step - 1);
  // };

  const onSubmit = async (values, actions) => {
    const { password, otp, email, mobile, country, firstName, lastName } =
      values;
    if (currentStep === 0) {
      const formData = {
        password: password,
      };
      const url = `users/${user?._id}/hospitals/${hospital?._id}/account/password`;
      try {
        const response = await api.post(url, formData);
        if (response) {
          setOtpId(response?.data?.data?.otpId);
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("password", err.response?.data.message);
        }
      }
    }

    if (currentStep === 1) {
      const formData = {
        otpId: otpId,
        otp: otp,
        type: "TRANSFER",
      };
      const url = `users/account/verify`;
      try {
        const response = await api.post(url, formData);
        if (response) {
          setToken(response?.data?.data?.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("otp", err.response?.data.message);
        }
      }
    }

    if (currentStep === 2) {
      const formData = {
        email: email,
        mobile: mobile,
        countryCode: country?.phone,
        firstName: firstName,
        lastName: lastName,
        token: token,
      };
      const url = `users/hospitals/${hospital?._id}/account/transfer`;
      try {
        const response = await api.post(url, formData);
        if (response) {
          setMyHospital(response?.data?.data?.hospital);
          handleClose();
          dispatch(showSnackbarAction(response?.data?.message, "success"));
        }
      } catch (err) {
        if (err) {
          handleClose();
          dispatch(showSnackbarAction(err.response.data.message, "error"));
        }
      }
    }
  };

  const initialValues = {
    password: "",
    otp: "",
    firstName: "",
    lastName: "",
    email: "",
    country: defaultCountry,
    mobile: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[currentStep],
    onSubmit: onSubmit,
  });

  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <Password formik={formik} />;
      case 1:
        return <Otp formik={formik} />;
      case 2:
        return <NewAdminDetails formik={formik} />;
      default:
        return <div>Page Not Found</div>;
    }
  }

  // console.log("formik", formik);

  return (
    <Card
      sx={{
        marginTop: 2,
        maxWidth: "90%",
        width: 600,
      }}
    >
      <CardHeader
        avatar={
          <TransferWithinAStationIcon sx={{ fontSize: 40 }} color="secondary" />
        }
        title="Transfer Account"
      />
      <CardContent>
        <StyledForm onSubmit={formik.handleSubmit} autoComplete="off">
          {renderPageContent(currentStep)}
          <Box
            sx={{
              display: "flex",
              marginTop: 6,
              marginBotton: 3,
              gap: 2,
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Button
                size="large"
                variant="contained"
                fullWidth
                type="submit"
                disabled={formik.isSubmitting}
              >
                {isLastPage ? "Submit" : "Next"}
              </Button>
              {formik.isSubmitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
          </Box>
        </StyledForm>
      </CardContent>
    </Card>
  );
}
