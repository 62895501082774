import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Button, Typography, Box } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import DialogAlert from "../alerts/DialogAlert";
import EditAdminForm from "./EditAdminForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Modal from "@mui/material/Modal";
import { PauseCircleFilledOutlined } from "@mui/icons-material";
import { loadAllAdminsAction } from "../../redux/admins/adminAction";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import api from "../../utilities/api";
import { loadAdminPanelRolesListAction } from "../../redux/list/listAction";
import PageHeading from "../reusables/PageHeading";
import AddAdmin from "../add-admin/AddAdmin";
import AddIcon from "@mui/icons-material/Add";

const CurrentAdmins = () => {
  const [rowToEdit, setRowToEdit] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  const [rowToSuspend, setRowToSuspend] = useState([]);
  const [rowToActivate, setRowToActivate] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);

  const [isSuspendDialogOpen, setIsSuspendDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { allAdmins, allAdminsLoading } = useSelector((state) => state.admins);
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  console.log("hospital", hospital);
  const subTitle = `Following admins have access to admin panel for ${hospital?.name}`;

  const handleCloseEditForm = () => {
    setShowEditForm(false);
    setRowToEdit([]);
  };

  const handleCloseAddForm = () => {
    setShowAddForm(false);
  };

  // open suspend or active dialogalert
  const openSuspendActivateDialog = useCallback(
    (id, row) => () => {
      if (row?.role === "Admin") {
        dispatch(showSnackbarAction("Can not suspend main Admin", "error"));
      } else {
        if (row?.suspended) {
          setRowToActivate(row);
          setIsActivateDialogOpen(true);
        } else {
          setRowToSuspend(row);
          setIsSuspendDialogOpen(true);
        }
      }
    },
    []
  );
  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      if (row?.role === "Admin") {
        dispatch(showSnackbarAction("Can not delete main Admin", "error"));
      } else {
        setIsDeleteDialogOpen(true);
        setRowToDelete(row);
      }
    },
    []
  );

  const deleteUser = async () => {
    try {
      let response = await api.delete(
        `bd/user/${rowToDelete?._id}/hospitals/${hospital?._id}?type=ADMINS`
      );
      dispatch(loadAllAdminsAction(response?.data?.data?.allUsers));
      closeDeleteDialog();
      dispatch(showSnackbarAction(response?.data?.message, "success"));
    } catch (error) {
      closeDeleteDialog();
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const suspendUser = async () => {
    const formData = {
      hospitalId: hospital?._id,
      userId: rowToSuspend?._id,
    };
    try {
      const response = await api.post("bd/user/suspend?type=ADMINS", formData);
      dispatch(loadAllAdminsAction(response.data.data.allUsers));
      closeSuspendDialog();
      dispatch(showSnackbarAction(response?.data?.message, "success"));
    } catch (err) {
      closeSuspendDialog();
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const activateUser = async () => {
    const formData = {
      hospitalId: hospital?._id,
      userId: rowToActivate?._id,
    };
    try {
      const response = await api.post("bd/user/activate?type=ADMINS", formData);
      dispatch(loadAllAdminsAction(response.data.data.allUsers));
      closeActivateDialog();
      dispatch(showSnackbarAction(response?.data?.message, "success"));
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
      closeActivateDialog();
    }
  };

  const requestToken = async (Id) => {
    try {
      const response = await api.post(
        `/hospitals/${hospital._id}/access/users/${Id}`
      );
      if (response) {
        dispatch(showSnackbarAction(response?.data?.message, "success"));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error?.response?.data?.message, "error"));
    }
  };

  const resendRequest = useCallback(
    (row) => () => {
      requestToken(row?._id);
    },
    []
  );

  const closeSuspendDialog = () => {
    setIsSuspendDialogOpen(false);
    setRowToSuspend([]);
  };
  const closeActivateDialog = () => {
    setIsActivateDialogOpen(false);
    setRowToActivate([]);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setRowToDelete([]);
  };

  const openEditForm = useCallback(
    (row) => () => {
      if (row?.role === "Admin") {
        dispatch(showSnackbarAction("Can not edit main Admin", "error"));
      } else {
        setRowToEdit(row);
        setShowEditForm(true);
      }
    },
    []
  );

  function MyRegisteredComponent({ data }) {
    return (
      <div>
        {data?.row?.webOnboarded ? (
          <Box>
            <Typography variant="subtitle">Registered</Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="subtitle" mb={0}>
              Pending
            </Typography>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => requestToken(data?.row?._id)}
              // sx={{
              //   paddingTop: "2px",
              //   paddingBottom: "2px",
              // }}
            >
              Resend request
            </Button>
          </Box>
        )}
      </div>
    );
  }

  const columns = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Name",
        minWidth: 120,
        type: "string",
        flex: 1,
        renderCell: (data) => {
          return data?.row?.webOnboarded ? data?.row?.fullName : "..";
        },
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 120,
        type: "string",
        flex: 1,
      },
      {
        field: "mobile",
        headerName: "Phone",
        minWidth: 80,
        type: "string",
        flex: 0.8,
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 80,
        type: "string",
        flex: 0.8,
      },
      {
        field: "departments",
        headerName: "Departments Assigned",
        minWidth: 120,
        type: "string",
        flex: 1.2,
        renderCell: ({ row }) => {
          return row?.departments ? (
            <Box>
              {row?.departments?.map((item) => (
                <Box key={item?.name}>
                  <Typography variant="subtitle">{item?.name}</Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              <Typography variant="subtitle">Hospital</Typography>
            </Box>
          );
        },
      },
      {
        field: "status",
        headerName: "Registration",
        minWidth: 80,
        type: "actions",
        flex: 1,
        renderCell: (data) => {
          return <MyRegisteredComponent data={data} />;
        },
      },

      {
        field: "suspended",
        headerName: "Previleges",
        description: "Status",
        type: "boolean",
        minWidth: 80,
        flex: 0.8,
        renderCell: (data) => {
          return data?.row?.suspended ? (
            <span style={{ color: "red" }}>Suspended</span>
          ) : (
            <span>Active</span>
          );
        },
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (data) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(data?.id, data?.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={openEditForm(data?.row)}
            />,
            <GridActionsCellItem
              icon={
                data?.row?.suspended ? (
                  <Tooltip title="Activate">
                    <AddCircleOutlineIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Suspend">
                    <PauseCircleFilledOutlined />
                  </Tooltip>
                )
              }
              label="Suspend"
              onClick={openSuspendActivateDialog(data?.id, data?.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [resendRequest, openEditForm, openDeleteDialog, openSuspendActivateDialog]
  );

  async function getAdminPanelRoles() {
    try {
      const response = await api.get("/acl/roles?webAccess=true");
      if (response)
        dispatch(loadAdminPanelRolesListAction(response?.data?.data));
    } catch (err) {
      dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
    }
  }

  const getCurrentAdmins = async (hospitalId) => {
    try {
      let response = await api.get(`hospitals/${hospitalId}/access`);
      dispatch(loadAllAdminsAction(response?.data?.data?.users));
    } catch (error) {
      dispatch(showSnackbarAction(error?.response?.data?.message, "error"));
    }
  };

  useEffect(() => {
    getCurrentAdmins(hospital?._id);
    getAdminPanelRoles();
  }, [hospital]);

  return (
    <Box>
      <PageHeading
        heading="Current Admins"
        subtitle={subTitle}
        action={
          <Button
            type="button"
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={() => setShowAddForm(true)}
          >
            Add
          </Button>
        }
      />
      <Paper
        sx={{
          // height: "auto",
          height: 800,
          width: "100%",
          marginTop: 2,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: { xs: 1, sm: 2, md: 4 },
          paddingRight: { xs: 1, sm: 2, md: 4 },
          boxShadow: 8,
        }}
      >
        <DataGrid
          getRowHeight={() => "auto"}
          // getEstimatedRowHeight={() => 100}
          sx={{
            border: 2,
            borderColor: "divider",
            "& .MuiDataGrid-cell": {
              borderBottom: 2,
              borderBottomColor: "divider",
              py: 1,
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: 2,
              borderBottomColor: "divider",
            },
            "& .MuiDataGrid-cell:hover": {
              color: "text.secondary",
            },
          }}
          loading={allAdminsLoading}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          columns={columns}
          rows={allAdmins}
        />
        <Modal
          open={showAddForm}
          onClose={handleCloseAddForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddAdmin handleClose={handleCloseAddForm} />
        </Modal>
        <Modal
          open={showEditForm}
          onClose={handleCloseEditForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <EditAdminForm
            rowToEdit={rowToEdit}
            handleClose={handleCloseEditForm}
          />
        </Modal>
        <DialogAlert
          open={isSuspendDialogOpen}
          onClose={closeSuspendDialog}
          handleYes={suspendUser}
          title={`Suspend user: ${rowToSuspend?.email}`}
          msg={`Are you sure you want to suspend user: ${rowToSuspend?.email}`}
        />
        <DialogAlert
          open={isActivateDialogOpen}
          onClose={closeActivateDialog}
          handleYes={activateUser}
          title={`Reactivate user: ${rowToActivate?.email}`}
          msg={`Are you sure you want to reactive user: ${rowToActivate?.email}`}
        />
        <DialogAlert
          open={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          handleYes={deleteUser}
          title={`Delete user ${rowToDelete?.email}`}
          msg={`Are you sure you want to delete user: ${rowToDelete?.email}?`}
        />
      </Paper>
    </Box>
  );
};

export default CurrentAdmins;
