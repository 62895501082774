import { IconButton, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useNavigate } from "react-router-dom";

import { drawerBackground, drawerTextColor } from "../navbar/utilAminNavbar";
import DDLogo from "../icons/ddLogo";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  // color: drawerTextColor,
  color: "#fff",
  // ".MuiTextField-root": { marginTop: "0" },
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  color: "#fff",
}));

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Box>
      <Paper
        sx={{
          backgroundColor: drawerBackground,
          opacity: 0.9,
          // height: 300,
          borderRadius: 0,
          padding: { xs: 2, sm: 5 },
        }}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2 }}
          rowSpacing={5}
          sx={{
            // border: "1px solid white",
            height: "100%",
          }}
        >
          <Grid size={{ xs: 12, lg: "grow" }}>
            <LogoBox>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <IconButton onClick={() => navigate("/")}>
                  <DDLogo />
                </IconButton>
                <Typography
                  noWrap
                  color="info.light"
                  variant="h5"
                  onClick={() => navigate("/")}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Daily Doc
                </Typography>
              </Box>
            </LogoBox>
          </Grid>
          <Grid
            // size={2.5}
            size={{ xs: 4, sm: 4, md: 4, lg: 2.5 }}
          >
            <StyledBox>
              <nav aria-label="footer legal links">
                <List
                  subheader={
                    <ListSubheader
                      sx={{
                        backgroundColor: "inherit",
                        color: "info.light",
                      }}
                    >
                      Company
                    </ListSubheader>
                  }
                >
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/price")}>
                      <ListItemText primary="Price" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/about")}>
                      <ListItemText primary="About us" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/contact")}>
                      <ListItemText primary="Contact us" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </nav>
            </StyledBox>
          </Grid>
          <Grid
            // size={2.5}
            size={{ xs: 4, sm: 4, md: 4, lg: 2.5 }}
          >
            <StyledBox>
              <nav aria-label="footer legal links">
                <List
                  subheader={
                    <ListSubheader
                      sx={{
                        backgroundColor: "inherit",
                        color: "info.light",
                      }}
                    >
                      Resources
                    </ListSubheader>
                  }
                >
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Webinar" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component="a" href="#simple-list">
                      <ListItemText primary="Case studies" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component="a" href="#simple-list">
                      <ListItemText primary="Q & A" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </nav>
            </StyledBox>
          </Grid>
          <Grid
            // size={2.5}
            size={{ xs: 4, sm: 4, md: 4, lg: 2.5 }}
          >
            <StyledBox>
              <nav aria-label="footer legal links">
                <List
                  subheader={
                    <ListSubheader
                      sx={{
                        backgroundColor: "inherit",
                        color: "info.light",
                      }}
                    >
                      Legal
                    </ListSubheader>
                  }
                >
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/privacy")}>
                      <ListItemText primary="Privacy policy" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/privacy")}>
                      <ListItemText primary="Terms of service" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </nav>
            </StyledBox>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
