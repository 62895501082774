import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function Password({ formik }) {
  return (
    <Box>
      <TextField
        type="password"
        name="password"
        autoComplete="on"
        // size="small"
        fullWidth
        label="Enter account password"
        id="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
    </Box>
  );
}
