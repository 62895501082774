import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import BedsTable from '../../components/beds/BedsTable';
import BedsToolbar from '../../components/beds/BedsToolbar';

function BedsPage() {
  return (
    <>
      <Container maxWidth='lg'>
        <PageHeading heading='Beds' />
        <BedsToolbar />
        <BedsTable />
      </Container>
    </>
  );
}

export default BedsPage;
