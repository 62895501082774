import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const Otp = (props) => {
  const { formik } = props;
  return (
    <>
      <Typography color="#757575" mb={4} variant="body1">
        Enter one-time password (OTP) sent your mobile
      </Typography>
      <TextField
        // size="small"
        name="otp"
        autoComplete="off"
        fullWidth
        label="Enter OTP sent on mobile"
        inputProps={{ maxLength: "6" }}
        value={formik.values.otp}
        onChange={formik.handleChange}
        error={formik.touched.me && Boolean(formik.errors.otp)}
        helperText={formik.touched.me && formik.errors.otp}
      />
    </>
  );
};

export default Otp;
