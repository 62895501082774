import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { getAccessControlData } from "../access-control/utilAccessControl";

function DbAccessControlTable() {
  const hospital = useSelector((state) => state.auth.hospital);
  const { accessControlData } = useSelector((state) => state?.accessControl);
  const navigate = useNavigate();

  useEffect(() => {
    getAccessControlData(hospital?._id);
  }, [hospital]);

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "DD-ID",
        description: "Daily Doc ID",
        type: "string",
        // minWidth: 150,
        flex: 0.8,
      },
      {
        field: "countryCode",
        headerName: "Country Code",
        description: "Country Code for mobiles",
        type: "string",
        // minWidth: 150,
        flex: 0.8,
      },
      {
        field: "mobile",
        headerName: "Mobile Num",
        description: "Employee mobile number",
        type: "string",
        // minWidth: 150,
        flex: 1,
      },
      {
        field: "registered",
        headerName: "Registered",
        description: "Employee has onboarded in the Daily Doc app",
        type: "boolean",
        flex: 0.8,
        // minWidth: 150,
      },
    ],
    []
  );

  return (
    <Paper
      sx={{
        height: 600,
        width: "100%",
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: { xs: 1, sm: 2, md: 4 },
        paddingRight: { xs: 1, sm: 2, md: 4 },
        boxShadow: 8,
      }}
    >
      {/* <Box mb={1}>
        <Button onClick={() => navigate("access-control")}>
          Mobile Numbers with access to Daily Doc
        </Button>
      </Box> */}
      <DataGrid
        showCellRightBorder={true}
        sx={{
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        rows={accessControlData}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </Paper>
  );
}

export default DbAccessControlTable;
