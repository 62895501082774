import { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";

function PasswordPage(props) {
  const { formik } = props;
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setshowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setshowConfirmPassword(!showConfirmPassword);
  return (
    <Stack spacing={{ xs: 2, sm: 4 }}>
      <Box
        sx={{
          border: "1px solid #E6E8F0",
          height: 56,
          borderRadius: 1,
          backgroundColor: "background.default",
          display: "flex",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography sx={{ flexGrow: 1 }}>{formik?.values?.phone}</Typography>
        <DoneIcon color="primary" />
      </Box>
      <Box
        sx={{
          border: "1px solid #E6E8F0",
          height: 56,
          borderRadius: 1,
          backgroundColor: "background.default",
          display: "flex",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography sx={{ flexGrow: 1 }}>{formik?.values?.email}</Typography>
        <DoneIcon color="primary" />
      </Box>
      <TextField
        fullWidth
        // id="password"
        name="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleClickShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        // id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        type={showConfirmPassword ? "text" : "password"}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment
              aria-label="toggle password visibility"
              position="end"
              onClick={handleClickShowConfirmPassword}
            >
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

export default PasswordPage;
