import Notfound from '../../components/notfound/Notfound';

function NotfoundPage() {
  return (
    <div>
      <Notfound />
    </div>
  );
}

export default NotfoundPage;
