import Profile from "../../components/profile/Profile";
import Container from "@mui/material/Container";

function ProfilePage() {
  return (
    <Container maxWidth="lg">
      <Profile />
    </Container>
  );
}

export default ProfilePage;
