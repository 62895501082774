import axios from "axios";

const getGeoInfo = async (setdefaultCountry) => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    if (response) {
      setdefaultCountry(response.data.country_code);
    }
  } catch (err) {
    if (err) {
      setdefaultCountry("IN");
    }
  }
};

export default getGeoInfo;
