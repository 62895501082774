import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function WhatAreYouWaiting() {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          marginBottom: 5,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
          }}
        >
          Are you ready to eliminate communication gaps in your hospital?
        </Typography>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
          }}
        >
          Why wait to improve outcomes and prevent medical errors!
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Chip
          label="SIGNUP FOR FREE"
          onClick={() => navigate("/onboarding")}
          clickable
          color="primary"
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 40,
          }}
        />
      </Box>
    </Box>
  );
}
