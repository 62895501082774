import Drawer from "@mui/material/Drawer";
import {
  drawerWidth,
  drawerBackground,
  drawerTextColor,
} from "./utilAminNavbar";
import DrawerList from "./DrawerList";

export default function MobileAdminDrawer({
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
}) {
  return (
    <Drawer
      // container={container}
      variant="temporary"
      open={mobileOpen}
      onTransitionEnd={() => handleDrawerTransitionEnd()}
      onClose={handleDrawerClose}
      sx={{
        display: { xs: "block", sm: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: drawerBackground,
          color: drawerTextColor,
        },
      }}
      slotProps={{
        root: {
          keepMounted: true, // Better open performance on mobile.
        },
      }}
    >
      <DrawerList handleDrawerClose={handleDrawerClose} />
    </Drawer>
  );
}
