import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import DDLogo from "../icons/ddLogo";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { logoutAction } from "../../redux/auth/authAction";
import GridViewIcon from "@mui/icons-material/GridView";

const HomeAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.body2,
}));

const pages = [
  {
    text: "Price",
    path: "/price",
  },
  {
    text: "About",
    path: "/about",
  },
  {
    text: "Contact us",
    path: "/contact",
  },
];

function MainAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <HomeAppBar position="static">
      <Toolbar
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
        disableGutters
      >
        <IconButton
          sx={{
            display: { xs: "none", md: "flex" },
            flexGrow: 0,
          }}
          onClick={() => navigate("/")}
        >
          <DDLogo className="dd-logo" />
        </IconButton>
        <Typography
          variant="h5"
          noWrap
          onClick={() => navigate("/")}
          sx={{
            flexGrow: 0,
            mr: 2,
            ml: 2,
            display: { xs: "none", md: "flex" },
            // fontFamily: "monospace",
            fontWeight: 700,
            // letterSpacing: ".3rem",
            color: "primary.main",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Daily Doc
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="primary"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {pages.map((page) => (
              <MenuItem
                key={page?.text}
                onClick={() => {
                  navigate(page?.path);
                  handleCloseNavMenu();
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  {page?.text}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <IconButton
          sx={{
            padding: 0,
            display: { xs: "flex", md: "none" },
            flexGrow: 0,
          }}
          onClick={() => navigate("/")}
        >
          <DDLogo />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          onClick={() => navigate("/")}
          sx={{
            flexGrow: 1,
            mr: 2,
            ml: 2,
            display: { xs: "flex", md: "none" },
            fontWeight: 700,
            color: "primary.main",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Daily Doc
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              // padding: "0px 100px",
              padding: { xs: "none", md: "0px 40px", lg: "0px 100px" },
              borderRadius: 40,
              backgroundColor: "rgb(235, 238, 243, 0.7)",
            }}
            direction="row"
            spacing={2}
          >
            {pages.map((e) => (
              <Chip
                key={e.text}
                onClick={() => navigate(e.path)}
                clickable
                label={e.text}
                sx={{
                  padding: 2.5,
                  borderRadius: 40,
                  backgroundColor: "inherit",
                  ":hover": {
                    backgroundColor: "neutral.200",
                  },
                }}
              />
            ))}
          </Stack>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          {isAuthenticated && user ? (
            // <Tooltip title="Open settings">
            //   <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            //     <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            //   </IconButton>
            // </Tooltip>
            <Tooltip title="Open settings">
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                onClick={handleOpenUserMenu}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    border: "1px solid",
                    backgroundColor: "background.default",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.neutral[200],
                    },
                    color: (theme) => theme.palette.neutral[500],
                  }}
                >
                  <Typography>
                    {user?.firstName?.charAt(0)?.toUpperCase()}
                  </Typography>
                </Avatar>
              </IconButton>
            </Tooltip>
          ) : (
            <Chip
              onClick={() => navigate("/login")}
              clickable
              color="primary"
              label="Login"
              sx={{
                height: { xs: "28px", md: "32px" },
                padding: { xs: "0px 4px", md: "20px 40px" },
                borderRadius: 40,
              }}
            />
          )}

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate("/dashboard");
              }}
            >
              <ListItemIcon>
                <GridViewIcon sx={{ width: 28, height: 28 }} />
              </ListItemIcon>
              <ListItemText>Dashboard </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate("/dashboard/profile");
              }}
            >
              <ListItemIcon>
                <Avatar sx={{ width: 24, height: 24 }} />
              </ListItemIcon>
              <ListItemText>Profile </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate("/dashboard/settings");
              }}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                dispatch(logoutAction());
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </HomeAppBar>
  );
}
export default MainAppBar;
