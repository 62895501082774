import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import MyPhoneInput from "./MyPhoneInput";

const BoxElement = styled(Box)(({ theme }) => ({
  // '.MuiGrid-item': { width: '100%' },
  ".PhoneInputCountry": {
    alignItems: "flex-start",
    marginTop: 12,
    width: 50,
  },
  ".PhoneInputCountrySelectArrow": {
    borderStyle: "solid",
    marginLeft: 8,
  },
}));

function MobilePage(props) {
  const { formik, defaultCountry } = props;

  return (
    <BoxElement spacing={2}>
      <PhoneInput
        type="text"
        // defaultCountry='IN'
        // countryCallingCodeEditable={false}
        defaultCountry={defaultCountry}
        international
        value={formik.values["phone"]}
        onChange={(e) => formik.setFieldValue("phone", e)}
        inputComponent={MyPhoneInput}
        rules={{ required: true }}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
      />
      <Typography mt={3}>
        Enter mobile number with country code to register
      </Typography>
    </BoxElement>
  );
}

export default MobilePage;
