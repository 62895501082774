import {
  OBUSER_LOADED,
  OBHOSPITAL_LOADED,
  DEFAULT_DEPARTMENTS_LOADED,
  OBUSERPROFILE_LOADED,
  ONBOARDING_RESET,
  OBDEPARTMENTS_LOADED,
  OBWARDS_LOADED,
} from "./onboardingTypes";

const initialState = {
  // obToken: localStorage.getItem('token'),
  isObAuthenticated: false,
  isLoading: true,
  obUser: null,
  obHospital: null,
  defaultDepartments: [],
  obUserProfile: null,
  dpmtloading: true,
  obDepartments: [],
  obWards: [],
};

function onboardingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OBUSER_LOADED:
      return {
        ...state,
        isObAuthenticated: true,
        isLoading: false,
        obUser: payload,
      };
    case OBHOSPITAL_LOADED:
      return {
        ...state,
        isLoading: false,
        obHospital: payload,
      };
    case DEFAULT_DEPARTMENTS_LOADED:
      return {
        ...state,
        defaultDepartments: payload,
        dpmtloading: false,
      };

    case OBUSERPROFILE_LOADED:
      return {
        ...state,
        isLoading: false,
        obUserProfile: payload,
      };
    case OBDEPARTMENTS_LOADED:
      return {
        ...state,
        obDepartments: payload,
      };
    case OBWARDS_LOADED:
      return {
        ...state,
        obWards: payload,
      };
    case ONBOARDING_RESET:
      return {
        ...state,
        // onToken:null,
        isObAuthenticated: false,
        isLoading: true,
        obUser: null,
        obHospital: null,
        defaultDepartments: [],
        obUserProfile: null,
        dpmtloading: true,
        obDepartments: [],
        obWards: [],
      };

    default:
      return state;
  }
}

export default onboardingReducer;
