// regex for number from 1000 to 999999999999999;
export const regex1000To15x9 =
  /^(100\d|10[1-9]\d|1[1-9]\d{2}|[2-9]\d{3}|[1-9]\d{4,14})$/;

export const progressBarStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
