import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { logoutAction } from "../../redux/auth/authAction";
import { resetOnboardingAction } from "../../redux/onboarding/onboardingAction";
import CelebrationIcon from "@mui/icons-material/Celebration";

const OnboardingSuccess = ({ msg }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetOnboardingAction());
    dispatch(logoutAction());
    localStorage.removeItem("token");
  }, []);

  return (
    <Paper
      sx={{
        width: 500,
        padding: { xs: 3, sm: 5, md: 6 },
        textAlign: "center",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <CelebrationIcon
        fontSize="large"
        color="secondary"
        sx={{
          fontSize: 56,
        }}
      />
      <Typography my={1}>
        Registration received. You can login to dashboard.
      </Typography>
      <Typography my={4}>
        Add all
        <Typography mx={1} variant="h6" component="span">
          Departments, Wards & Beds{" "}
        </Typography>{" "}
        through admin dashboard
        <Typography mx={1} variant="h6" component="span">
          Before
        </Typography>
        using the app.
      </Typography>
      <Typography>{msg}</Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          navigate("/login", { state: {} });
        }}
      >
        Okay
      </Button>
    </Paper>
  );
};

export default OnboardingSuccess;
