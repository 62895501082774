import api from '../../utilities/api';
import {
  loadFeaturesAction,
  loadPlansAction,
} from '../../redux/subscription/subscriptionAction';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { store } from '../../redux/store';

import { Box, Typography } from '@mui/material';

export const getPrice = (currency, price, discountPercentage) => {
  const calculatedPrice = price * (1 - discountPercentage / 100);
  let string;
  if (calculatedPrice === 0 || calculatedPrice < 0) {
    return (
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: {
              xs: '0.87rem',
              sm: '1.125rem',
              textDecoration: 'line-through',
              textAlign: 'center',
            },
          }}
        >
          {currency} {price}/user/month
        </Typography>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            fontSize: {
              xs: '0.87rem',
              sm: '1.125rem',
            },
          }}
        >
          Free
        </Typography>
        <Typography variant="subtitle" sx={{ textAlign: 'center' }}>
          Billed annually
        </Typography>
      </Box>
    );
  }
  if (calculatedPrice > 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: {
              xs: '0.87rem',
              sm: '1.125rem',
              textDecoration: 'line-through',
              textAlign: 'center',
            },
          }}
        >
          {currency} {price}/user/month
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 0.5,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: '0.87rem',
                sm: '1.125rem',
              },
            }}
          >
            {currency}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: '0.87rem',
                sm: '1.125rem',
              },
            }}
          >
            {calculatedPrice}/user/month
          </Typography>
        </Box>
        <Typography variant="subtitle" sx={{ textAlign: 'center' }}>
          Billed annually
        </Typography>
      </Box>
    );
  }
  return string;
};

export const getPriceUsingCredit = (currency, price, pricePaid) => {
  const calculatedPrice = price;
  let string;
  if (calculatedPrice === 0 || calculatedPrice < 0) {
    return (
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: {
              xs: '0.87rem',
              sm: '1.125rem',
              textDecoration: 'line-through',
              textAlign: 'center',
            },
          }}
        >
          {currency} {price}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            fontSize: {
              xs: '0.87rem',
              sm: '1.125rem',
            },
          }}
        >
          Free
        </Typography>
        <Typography variant="subtitle" sx={{ textAlign: 'center' }}>
          Billed annually
        </Typography>
      </Box>
    );
  }
  if (calculatedPrice > 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: {
              xs: '0.87rem',
              sm: '1.125rem',
              textDecoration: 'line-through',
              textAlign: 'center',
            },
          }}
        >
          {currency} {price}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 0.5,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: '0.87rem',
                sm: '1.125rem',
              },
            }}
          >
            {pricePaid > 0 ? currency : ''}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: '0.87rem',
                sm: '1.125rem',
              },
            }}
          >
            {pricePaid > 0 ? pricePaid : 'Free'}
          </Typography>
        </Box>
        <Typography variant="subtitle" sx={{ textAlign: 'center' }}>
          Billed annually
        </Typography>
      </Box>
    );
  }
  return string;
};

export const plans = [
  {
    currency: 'Rs',
    title: 'Starter',
    price: 100,
    subtitle: 'Good for small hospitals',
    discountPercentage: 100,
  },
  // {
  //   title: "Growth",
  //   price: 95,
  //   subtitle: "Good for large hospitals",
  // },
  {
    currency: 'Rs',
    title: 'Enterprise',
    price: 500,
    subtitle: 'Good as enterprise solution',
    discountPercentage: 40.6,
  },
];

export const features = [
  { feature: 'Data Retention', plan: ['Starter', 'Growth', 'Enterprise'] },
  { feature: 'No limit on users', plan: ['Starter', 'Growth', 'Enterprise'] },
  {
    feature: '1-1 Secure Messaging. All messages encrypted.',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Group Messaging',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  //   {
  //     feature: "Messaging on patient channel",
  //     plan: ["Starter", "Growth", "Enterprise"],
  //   },
  { feature: 'Send Pictures', plan: ['Starter', 'Growth', 'Enterprise'] },
  { feature: 'Send Documents', plan: ['Starter', 'Growth', 'Enterprise'] },
  { feature: 'Send Audio files', plan: ['Starter', 'Growth', 'Enterprise'] },
  { feature: 'Send Video files', plan: ['Starter', 'Growth', 'Enterprise'] },
  { feature: 'Hospital Directory', plan: ['Starter', 'Growth', 'Enterprise'] },

  {
    feature: 'Message read status',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  //   {
  //     feature: "Offline messaging support",
  //     plan: ["Starter", "Growth", "Enterprise"],
  //   },

  { feature: 'Typing Indicator', plan: ['Starter', 'Growth', 'Enterprise'] },
  //   { feature: "Online status", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: 'Chat History', plan: ['Starter', 'Growth', 'Enterprise'] },
  {
    feature: 'Add patients to DD app',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Search doctor or nurse or any provider by bed no.',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Search doctor or nurse or any provider by patients name',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  { feature: 'Discharge patients', plan: ['Starter', 'Growth', 'Enterprise'] },
  {
    feature: 'Provider patient list',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Update patients room',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Updated care team',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },

  { feature: 'Analytics Dashboard', plan: ['Starter', 'Growth', 'Enterprise'] },
  {
    feature: 'Transfer account to new hospital admin',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Add subadmin to manage few departments',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Add Secretary for each or multiple departments',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Rapid onboarding with CSV',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  {
    feature: 'Minimal data entry for admins',
    plan: ['Starter', 'Growth', 'Enterprise'],
  },
  { feature: 'Community support', plan: ['Starter', 'Growth', 'Enterprise'] },
  { feature: 'Support by email', plan: ['Starter', 'Growth', 'Enterprise'] },
  {
    feature: '24x7 Support by WhatsApp',
    plan: ['Growth', 'Enterprise'],
  },
  // { feature: "Oncall schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "Physcian schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "Nursing schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "All other staff schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "Live care team", plan: ["Growth", "Enterprise"] },
  // { feature: "Discharge planning", plan: ["Growth", "Enterprise"] },
  { feature: 'Dedicated Server', plan: ['Enterprise'] },
  { feature: 'Host own your own premises', plan: ['Enterprise'] },
];

export const getPlansAndFeatures = async () => {
  try {
    const response = await api.get('/subscriptions/plans/dashboard');

    store.dispatch(loadFeaturesAction(response?.data?.data?.features));
    store.dispatch(loadPlansAction(response?.data?.data?.plans));
  } catch (error) {
    store.dispatch(showSnackbarAction(error.response.data.message, 'error'));
  }
};
