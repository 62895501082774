import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { parsePhoneNumber } from 'react-phone-number-input';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';

import MobilePage from '../reusables/MobilePage';
import MobileOtp from './MobileOtp';
import EmailPage from './EmailPage';
import EmailOtp from './EmailOtp';
import PasswordPage from './PasswordPage';
import HospitalPage from './HospitalPage';
import DepartmentsPage from './DepartmentsPage';
import OnboardingSuccess from './OnboardingSuccess';
import ObWardsPage from './ObWardsPage';
import ObBedsPage from './ObBedsPage';
import PlanOptions from '../subscription/PlanOptions';
import CalculatePrice from '../subscription/CalculatePrice';

import api from '../../utilities/api';
import setAuthToken from '../../utilities/setAuthToken';
import ExitDialog from './ExitDialog';

import {
  loadObUserAction,
  loadObHospitalAction,
  loadObuserProfileAction,
  resetOnboardingAction,
} from '../../redux/onboarding/onboardingAction';
import { logoutAction } from '../../redux/auth/authAction';
import {
  removeCountryListAction,
  removeStateListAction,
  removeCityListAction,
} from '../../redux/list/listAction';
import { validationSchema } from './validationSchema';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { getPlansAndFeatures } from '../subscription/util';

const PaperElement = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  '.stepper-form': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5),
  },
}));

// No of headings in steps array will be equal to numb of  actual steps required to complete form
// its different from steppers steps

const steps = [
  'Enter Mobile',
  'Mobile OTP',
  'Provide Email',
  'Email OTP',
  'Set Password',
  'User and Hospital Details',
  'Choose Departments',
  'Add Wards',
  'Add Beds',
  'Choose Plan',
  'Plan Estimate',
];

// No of headings in headings array actually show the steps diaplayed in stepper

const headings = [
  'Login Details',
  'User & Hospital Details',
  'Choose Departments',
  'Add Wards',
  'Add Beds',
  'Choose Plan',
];

const HospitalOnboarding = () => {
  const [defaultCountry, setdefaultCountry] = useState('IN');
  const [activeStep, setactiveStep] = useState(0);
  const [activeHeading, setactiveHeading] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const [OTPid, setOTPid] = useState('');
  const [open, setOpen] = useState(false);
  const [priceData, setPriceData] = useState(null);

  // for calling action creator
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { obUser, obHospital, obUserProfile } = useSelector(
    (state) => state.onboarding
  );
  const { plans } = useSelector((state) => state.subscription);

  // function for going to the back page.
  function handleBack(step, heading) {
    if (step > 5 && step < 10) {
      setactiveStep(step - 1);
      setactiveHeading(heading - 1);
    }
    if (step === 10) {
      setactiveStep(step - 1);
      // setactiveHeading(heading - 1);
    }
  }

  const onSubmit = async (values, actions) => {
    if (activeStep === 0) {
      const phoneNumber = parsePhoneNumber(values.phone);
      const formData = {
        user: {
          countryCode: phoneNumber?.countryCallingCode,
          mobile: phoneNumber?.nationalNumber,
          rawMobile: phoneNumber?.number,
        },
      };
      try {
        const response = await api.post('/auth/ob0', formData);
        if (response) {
          setOTPid(response.data.data._id);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('phone', err.response?.data.message);
        }
      }
    }

    if (activeStep === 1) {
      let { mobileOtp } = values;
      const formData = {
        otpData: {
          _id: OTPid,
          otp: mobileOtp,
          type: 'mobile',
        },
      };

      try {
        const response = await api.post('/auth/ob0/verify/otp', formData);

        const { data } = response.data;
        const obUser = data.user;
        if (response) {
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          dispatch(loadObUserAction(obUser));
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('mobileOtp', err.response?.data.message);
        }
      }
    }

    if (activeStep === 2) {
      const { email } = values;
      const formData = {
        user: {
          email,
          user: obUser?._id,
        },
      };

      try {
        const response = await api.post('/auth/ob1', formData);
        if (response) {
          setOTPid(response.data.data._id);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('email', err.response?.data.message);
        }
      }
    }

    if (activeStep === 3) {
      let { emailOtp } = values;

      const formData = {
        otpData: {
          _id: OTPid,
          otp: emailOtp,
          type: 'email',
        },
      };

      try {
        const response = await api.post('/auth/ob1/verify/otp', formData);
        if (response) {
          setAuthToken(response.data.data.token);
          dispatch(loadObUserAction(response.data.data.user));
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('emailOtp', err.response?.data.message);
        }
      }
    }

    if (activeStep === 4) {
      const { password, confirmPassword } = values;
      const formData = {
        user: {
          _id: obUser?._id,
          userPassword: password,
          confirmPassword,
        },
      };

      try {
        const response = await api.post('/onboarding/ob2', formData);
        if (response) {
          setAuthToken(response?.data?.data?.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('password', err?.response?.data?.message);
        }
      }
    }

    if (activeStep === 5) {
      const {
        firstName,
        lastName,
        hospitalName,
        hospitalShortName,
        country,
        state,
        city,
      } = values;

      const formData = {
        user: {
          _id: obUser._id,
          firstName,
          lastName,
        },
        hospital: {
          _id: obHospital?._id || '',
          name: hospitalName,
          code: hospitalShortName,
          country: country.value,
          state: state.value,
          city: city.value,
        },
      };

      try {
        const response = await api.post('/onboarding/ob3', formData);
        if (response) {
          setAuthToken(response.data.data.token);
          dispatch(loadObUserAction(response.data.data.user));
          dispatch(loadObHospitalAction(response.data.data.hospital));
          dispatch(loadObuserProfileAction(response.data.data.userProfile));
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('firstName', err.response?.data.message);
        }
      }
    }
    if (activeStep === 6) {
      let { departments } = values;
      const formData = {
        hospital: obHospital?._id,
        departments,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
      };
      try {
        const response = await api.post('/onboarding/ob4', formData);
        if (response) {
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('departments', err.response?.data.message);
        }
      }
    }

    if (activeStep === 7) {
      let { wards } = values;
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        wards: wards,
      };

      try {
        const response = await api.post('/onboarding/ob5', formData);
        if (response) {
          actions.setFieldValue('wards', response.data.data.savedWards);
          // update beds arry
          if (values.beds?.length > 0) {
            const newBeds = values.beds?.map((item) => {
              const updatedBed = response.data.data.savedWards.find(
                (i2) => i2.uid === item.wardUid
              );
              return updatedBed
                ? {
                    ...item,
                    wardId: updatedBed?._id,
                    wardName: updatedBed.name,
                  }
                : item;
            });
            actions.setFieldValue('beds', newBeds);
          }

          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('wards', err.response?.data.message);
        }
      }
    }

    if (activeStep === 8) {
      let { beds } = values;
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        wardBeds: beds,
      };

      try {
        const response = await api.post('/onboarding/ob6', formData);
        if (response) {
          console.log('response s8', response);
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('beds', err.response?.data.message);
        }
      }
    }

    if (activeStep === 9) {
      let { plan, users, years } = values;
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        planId: plan,
        usersCount: users,
        durationInYear: years,
      };
      try {
        const response = await api.post(
          `/onboarding/subscription/plan?onBoardingStep=${true}`,
          formData
        );
        if (response) {
          setPriceData(response?.data?.data?.calculatePriceData);
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError('plan', err.response?.data.message);
        }
      }
    }
    if (activeStep === 10) {
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        _id: priceData?._id,
      };
      try {
        const response = await api.post(
          '/onboarding/ob7/subscription/payment',
          formData
        );
        if (response && priceData?.price === 0) {
          setactiveStep(activeStep + 1);
        } else if (response && priceData?.price > 0) {
          navigateToPayment(response?.data.data?.redirectUrl);
        }
      } catch (err) {
        if (err) {
          dispatch(showSnackbarAction(err.response.data.message, 'error'));
        }
      }
    }
  };

  // onSubmit ends

  const initialValues = {
    phone: '',
    mobileOtp: '',
    email: '',
    emailOtp: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    hospitalName: '',
    hospitalShortName: '',
    country: null,
    state: null,
    city: null,
    departments: [],
    ward: '',
    wards: [],
    selectedWard: null,
    bed: '',
    beds: [],
    plan: '67d45375e93de5d5b3f983ac',
    users: 1000,
    years: 1,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[activeStep],
    onSubmit: onSubmit,
  });

  const resetMobile = () => {
    formik.resetForm({
      values: initialValues,
    });
    setactiveStep(0);
    setactiveHeading(0);
  };

  const resetEmail = () => {
    const newValues = {
      phone: formik.values.phone,
      ...initialValues,
    };
    formik.resetForm({
      values: newValues,
    });
    setactiveStep(2);
    setactiveHeading(0);
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleExit = () => {
    formik.resetForm({
      values: initialValues,
    });
    dispatch(resetOnboardingAction());
    dispatch(logoutAction());
    setactiveStep(0);
    setactiveHeading(0);
    setOpen(false);
    navigate('/');
  };

  const resendMobileOtp = async () => {
    const phoneNumber = parsePhoneNumber(formik.values.phone);
    const formData = {
      user: {
        countryCode: phoneNumber?.countryCallingCode,
        mobile: phoneNumber?.nationalNumber,
        rawMobile: phoneNumber?.number,
      },
    };
    try {
      const response = await api.post('/auth/ob0', formData);
      if (response) {
        setOTPid(response.data.data._id);
        formik.setTouched({});
        formik.setSubmitting(false);
        // setactiveStep(activeStep + 1);
      }
    } catch (err) {
      if (err) {
        formik.setFieldError('phone', err.response?.data.message);
      }
    }
  };

  const resendEmailOtp = async () => {
    const formData = {
      user: {
        email: formik.values.email,
        user: obUser?._id,
      },
    };

    try {
      const response = await api.post('/auth/ob1', formData);

      if (response) {
        setOTPid(response.data.data._id);
        formik.setTouched({});
        formik.setSubmitting(false);
        // setactiveStep(activeStep + 1);
      }
    } catch (err) {
      if (err) {
        formik.setFieldError('email', err.response?.data.message);
      }
    }
  };

  const applyPromoCode = async () => {
    try {
      console.log({ formik });
      let formData = {
        usersCount: priceData?.usersCount,
        hospital: obHospital?._id,
        planId: priceData?.planId,
        durationInYear: formik.values.years,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        promoCode: formik?.values?.promoCode,
      };

      const response = await api.post(
        `/onboarding/subscription/plan?onBoardingStep=${true}`,
        formData
      );
      if (response) {
        setPriceData(response?.data?.data?.calculatePriceData);
        setAuthToken(response.data.data.token);
      }
    } catch (err) {
      dispatch(showSnackbarAction(err?.response?.data?.message, 'error'));
    }
  };

  // function to show part of the form based on the current step
  function renderStepContent(step) {
    switch (step) {
      case 0:
        return <MobilePage defaultCountry={defaultCountry} formik={formik} />;
      case 1:
        return (
          <MobileOtp
            resetMobile={resetMobile}
            formik={formik}
            resendMobileOtp={resendMobileOtp}
          />
        );

      case 2:
        return <EmailPage formik={formik} />;
      case 3:
        return (
          <EmailOtp
            resetEmail={resetEmail}
            formik={formik}
            resendEmailOtp={resendEmailOtp}
          />
        );

      case 4:
        return <PasswordPage formik={formik} />;
      case 5:
        return <HospitalPage formik={formik} />;
      case 6:
        return <DepartmentsPage formik={formik} />;
      case 7:
        return <ObWardsPage formik={formik} />;
      case 8:
        return <ObBedsPage formik={formik} />;
      case 9:
        return <PlanOptions plans={plans} formik={formik} />;
      case 10:
        return (
          <CalculatePrice
            applyPromocode={applyPromoCode}
            priceData={priceData}
            formik={formik}
          />
        );

      default:
        return null;
    }
  }

  // async function getGeoInfo() {
  //   try {
  //     const response = await axios.get("https://ipapi.co/json/");
  //     if (response) {
  //       setdefaultCountry(response.data.country_code);
  //     }
  //   } catch (err) {
  //     if (err) setdefaultCountry("IN");
  //   }
  // }

  const submitButtonIcon = isLastStep ? (
    priceData?.price > 0 ? (
      <CreditCardIcon />
    ) : null
  ) : (
    <NavigateNextIcon />
  );

  const navigateToPayment = (paymentUrl) => {
    window.location.href = paymentUrl;
  };

  useEffect(() => {
    // getGeoInfo();
    getPlansAndFeatures();
    return () => {
      dispatch(removeCountryListAction());
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
      dispatch(resetOnboardingAction());
    };
  }, []);

  console.log('firmik', formik);
  // console.log("active step", activeStep);
  // console.log("active heading", activeHeading);

  return (
    <Box>
      {activeStep === steps.length ? (
        <OnboardingSuccess />
      ) : (
        <>
          <Typography mb={3} variant="h4" textAlign="center">
            Welcome to Daily Doc
          </Typography>
          <PaperElement elevation={1}>
            {/* stepper for none mobile view */}
            <Box
              sx={{
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <Grid container columnSpacing={{ xs: 2, sm: 4, md: 5 }}>
                <Grid size={4}>
                  <Stepper activeStep={activeHeading} orientation="vertical">
                    {headings.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          optional={
                            index === headings?.length - 1 ? (
                              <Typography variant="caption">
                                Last step
                              </Typography>
                            ) : null
                          }
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid size={8}>
                  <form
                    className="stepper-form"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                  >
                    <Box>{renderStepContent(activeStep)}</Box>
                    <Stack
                      sx={{ marginTop: 5 }}
                      direction="row-reverse"
                      spacing={2}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        // fullWidth
                        disabled={formik.isSubmitting}
                        type="submit"
                        endIcon={submitButtonIcon}
                      >
                        {isLastStep
                          ? priceData?.price > 0
                            ? 'Pay'
                            : 'Submit'
                          : 'Next'}
                      </Button>
                      {activeStep > 5 && (
                        <Button
                          onClick={() => handleBack(activeStep, activeHeading)}
                          variant="outlined"
                          startIcon={<ArrowBackIcon />}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={openDialog}
                      >
                        Exit
                      </Button>
                    </Stack>
                  </form>
                </Grid>
              </Grid>
            </Box>
            {/* stepper for mobile view only */}
            <Box
              sx={{ maxWidth: '100%', display: { xs: 'block', sm: 'none' } }}
            >
              <Stepper activeStep={activeHeading} orientation="vertical">
                {headings.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      optional={
                        index === headings?.length - 1 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {label}
                    </StepLabel>
                    <StepContent>
                      <form
                        className="stepper-form"
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                      >
                        <Box>{renderStepContent(activeStep)}</Box>
                        <Stack
                          sx={{ marginTop: 3 }}
                          direction="row-reverse"
                          spacing={{ xs: 1, sm: 2 }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            // fullWidth
                            disabled={formik.isSubmitting}
                            type="submit"
                            endIcon={submitButtonIcon}
                          >
                            {isLastStep
                              ? priceData?.price > 0
                                ? 'Pay'
                                : 'Submit'
                              : 'Next'}
                          </Button>
                          {activeStep > 5 && (
                            <Button
                              onClick={() =>
                                handleBack(activeStep, activeHeading)
                              }
                              variant="outlined"
                              startIcon={<ArrowBackIcon />}
                            >
                              Back
                            </Button>
                          )}
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={openDialog}
                          >
                            Exit
                          </Button>
                        </Stack>
                      </form>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </PaperElement>
        </>
      )}
      <ExitDialog
        handleExit={handleExit}
        open={open}
        closeDialog={closeDialog}
      />
    </Box>
  );
};

export default HospitalOnboarding;
