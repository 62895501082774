import { GEL_ALL_HOSPITALS } from "./hospitalsTypes";

const initialState = {
  allHospitals: [],
};

export default function adminHospitalsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GEL_ALL_HOSPITALS:
      return {
        ...state,
        allHospitals: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
