import HospitalTable from "../../components/add-hosppital/HospitalTable";
import Container from "@mui/material/Container";

const MyHospitalsPage = () => {
  return (
    <Container maxWidth="lg">
      <HospitalTable />
    </Container>
  );
};

export default MyHospitalsPage;
