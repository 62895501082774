import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import AddMobileForm from "./AddMobileForm";
import Paper from "@mui/material/Paper";
import { Upload as UploadIcon } from "../icons/Upload";
import { Typography } from "@mui/material";

export const AccessControlToolbar = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 3 }}
      >
        <Grid size={{ xs: 12, sm: "auto" }}>
          <Paper
            sx={{
              height: "100%",
              minWidth: 160,
              textAlign: "center",
              paddingTop: { xs: 2, sm: 4 },
              paddingBottom: { xs: 2, sm: 4 },
              paddingLeft: { xs: 2, sm: 3, md: 4 },
              paddingRight: { xs: 2, sm: 3, md: 4 },
            }}
          >
            <Box
              sx={{
                marginBottom: 1,
              }}
            >
              <Typography variant="h6">Bulk Upload</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Add multiple users
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                onClick={() => {
                  navigate("/dashboard/upload-ac-csv");
                }}
              >
                Upload CSV
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, sm: "grow" }}>
          <Paper
            sx={{
              height: "100%",
              paddingTop: { xs: 2, sm: 4 },
              paddingBottom: { xs: 2, sm: 4 },
              paddingLeft: { xs: 4, sm: 2, md: 4 },
              paddingRight: { xs: 4, sm: 2, md: 4 },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Box
              sx={{
                marginBottom: 1,
              }}
            >
              <Typography variant="h6">Single Upload</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Add one user at a time. Add country code withouth + sign, ex:
                91. Add mobile number without any spaces or dashes or special
                characters.
              </Typography>
            </Box>
            <Box>
              <AddMobileForm />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccessControlToolbar;
