import api from "../../utilities/api";
import { showSnackbarAction } from "../../redux/alert/alertAction";

import {
  getDepartmentsAction,
  getDefaultDepartmentsAction,
} from "../../redux/departments/departmentsAction";

import { store } from "../../redux/store";

export const getDepartmentsData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/departments`;
  try {
    const response = await api.get(url);
    if (response) store.dispatch(getDepartmentsAction(response?.data?.data));
  } catch (err) {
    store.dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
  }
};

export const getDefaultDepartments = async () => {
  const url = "/onboarding/ob4/departments";
  try {
    const response = await api.get(url);
    if (response)
      store.dispatch(getDefaultDepartmentsAction(response?.data?.data));
  } catch (err) {
    store.dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
  }
};
