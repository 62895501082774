import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

export default function ObBed({ open, onClose, formik, bedErr, setBedErr }) {
  // const [bedErr, setBedErr] = useState("");
  const addBeds = () => {
    if (!formik.values.bed?.length > 0) {
      setBedErr("Ward & bed, both are required");
      return;
    }
    if (formik.values.selectedWard === null || undefined) {
      setBedErr("Ward & bed, both are required");
      return;
    }
    if (!Object.keys(formik.values.selectedWard)?.length > 0) {
      setBedErr("Ward & bed, both are required");
      return;
    }
    // const match = formik.values.wards.includes(formik.values.ward);

    let match = formik.values.beds?.find(
      (item) =>
        formik.values.selectedWard?._id === item?.wardId &&
        formik.values.bed === item?.bedName
    );
    if (match) {
      setBedErr("Can not add duplicate bed in same ward.");
      return;
    }

    if (
      formik.values.bed?.length > 0 &&
      Object.keys(formik.values.selectedWard)?.length > 0 &&
      !match
    ) {
      formik.setFieldValue("beds", [
        ...formik.values.beds,
        {
          wardName: formik.values.selectedWard.name,
          bedName: formik.values.bed,
          id: uuid(),
          wardId: formik.values.selectedWard._id,
          wardUid: formik.values.selectedWard.uid,
        },
      ]);
      formik.setFieldValue("bed", "");
      onClose();
    }
  };

  useEffect(() => {
    return () => {
      formik.setFieldValue("bed", "");
      formik.setFieldValue("selectedWard", null);
    };
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
        <DialogTitle>Add Bed</DialogTitle>
        <DialogContent>
          <Grid container spacing={{ xs: 1 }}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={formik.values.selectedWard}
                name="selectedWard"
                options={formik.values.wards || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("selectedWard", newValue);
                  if (newValue && formik.values.bed.length > 0) {
                    setBedErr("");
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    margin="normal"
                    label="Select ward"
                    required
                    value={formik.values?.selectedWard}
                    error={
                      formik.touched.selectedWard &&
                      Boolean(formik.errors.selectedWard)
                    }
                    helperText={
                      formik.touched.selectedWard && formik.errors.selectedWard
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ marginTop: 2 }}
                fullWidth
                id="bed"
                name="bed"
                label="Add bed name*"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                value={formik.values.bed}
                onChange={(e) => {
                  formik.handleChange(e);
                  if (
                    e.target.value?.length > 0 &&
                    formik.values.selectedWard
                  ) {
                    setBedErr("");
                  }
                }}
                error={formik.touched.bed && Boolean(formik.errors.bed)}
                helperText={formik.touched.bed && formik.errors.bed}
              />
            </Grid>
          </Grid>
          {
            <Box sx={{ color: "error.main", paddingLeft: 1, mt: 1 }}>
              {bedErr}
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Box sx={{ position: "relative" }}>
            <Button fullWidth type="button" onClick={() => addBeds()}>
              Save
            </Button>
            {formik.isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
