import { GEO_INFO_LOADED } from "./geoInfoTypes";
import setAuthToken from "../../utilities/setAuthToken";

const initialState = {
  isLoading: true,
  geoInfo: null,
};

function geoInfoReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GEO_INFO_LOADED:
      return {
        ...state,
        isLoading: false,
        geoInfo: payload,
      };
    default:
      return state;
  }
}

export default geoInfoReducer;
