import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import SubscriptionCard from './SubsciptionCard';

const Subscription = ({ features, planData }) => {
  return (
    <Box>
      <Stack sx={{ textAlign: 'center', marginBottom: 3 }}>
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              xs: '1.4rem',
              sm: '2.25rem',
            },
          }}
        >
          Upgrade today. Boost your service!
        </Typography>
        <Typography variant="body1" sx={{ color: 'gray', marginTop: 1 }}>
          We are offering starter plan for free for limited time.
        </Typography>
      </Stack>
      <Box
        sx={{
          marginLeft: { xs: 0, sm: 2, md: 8 },
          marginRight: { xs: 0, sm: 2, md: 8 },
        }}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 5 }}
          rowSpacing={{ xs: 1, sm: 2, md: 5 }}
        >
          {planData?.map((item, idx) => (
            <Grid
              size={{ xs: 6 }}
              key={idx}
              display="flex"
              justifyContent="center"
            >
              <SubscriptionCard
                plan={item}
                // activePlan={activePlan}
                features={features}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Subscription;
