import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import Typography from "@mui/material/Typography";
import WaveIcon from "../icons/WaveIcon";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

export default function Hero() {
  const [isPaused, setIsPaused] = useState(true);
  const [videoWidth, setVideoWidth] = useState(95);
  const videoRef = useRef(null); // Ref to access the video element
  const handleScroll = () => {
    // Get the current scroll position
    const scrollY = window.scrollY;
    // Calculate the width based on the scroll position
    const newWidth = Math.min(94 + scrollY / 20, 100); // Max width at 100%
    setVideoWidth(newWidth);
  };

  // Event handler for when the video is paused
  const handlePause = () => {
    setIsPaused(true);
  };
  // Event handler for when the video is played
  const handlePlay = () => {
    setIsPaused(false);
  };

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // this autoplays video when fully in viewport
  // useEffect(() => {
  //   // Set up the intersection observer
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       // Check if the video is fully visible in the viewport
  //       if (entry.isIntersecting) {
  //         // Check if the entire video is within the viewport (fully visible)
  //         const { top, bottom } = entry.boundingClientRect;
  //         if (top >= 0 && bottom <= window.innerHeight) {
  //           videoRef.current.play(); // Play the video if fully visible
  //         }
  //       } else {
  //         videoRef.current.pause(); // Pause the video if it's not fully visible
  //       }
  //     },
  //     {
  //       threshold: 1.0, // This means the entire element must be visible to trigger the observer
  //     }
  //   );

  //   // Observe the video element
  //   const videoElement = videoRef.current;
  //   if (videoElement) {
  //     observer.observe(videoElement);
  //   }

  //   // Cleanup the observer when the component unmounts
  //   return () => {
  //     if (videoElement) {
  //       observer.unobserve(videoElement);
  //     }
  //   };
  // }, []);

  const navigate = useNavigate();

  const chipsInChip = (
    <Box>
      <span
        style={{
          marginRight: 4,
        }}
      >
        One app, Endless
      </span>
      <Chip
        avatar={
          <CelebrationOutlinedIcon
            sx={{
              "&.MuiChip-avatar": {
                color: (theme) => theme.palette.warning.light,
              },
            }}
          />
        }
        label="Possibilities"
        sx={{
          backgroundColor: "rgb(204, 229, 255)",
          height: "auto",
          "& .MuiChip-label": {
            marginLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 1,
            paddingRight: 1,
          },
        }}
      />
    </Box>
  );

  return (
    <Box>
      <Box>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Box>
            <Chip
              sx={{
                border: "1px solid rgb(226, 226, 229)",
                height: "auto",
                "& .MuiChip-label": {
                  padding: 0.5,
                },
              }}
              variant="outlined"
              label={chipsInChip}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  xs: "2rem", // For extra small screens (mobile)
                  sm: "3rem", // h2 default size for all other screens
                },
              }}
            >
              Secure Messaging
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  xs: "2rem", // For extra small screens (mobile)
                  sm: "3rem", // h2 default size for all other screens
                },
              }}
            >
              Platform for Hospitals!
            </Typography>
          </Box>
          <Box>
            <WaveIcon />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Typography>
          Daily Doc is a secure Messaging platform for doctors, nurses, & other
          helthcare providers.
        </Typography>
        <Typography>
          Daily Doc prevents too many phone calls and non stop interruptions in
          daily hospital workflow.
        </Typography>
        <Typography>
          It's patient centric architecture decreases erros and imrpoves
          outcomes.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 4,
        }}
      >
        <Chip
          label="SIGNUP FOR FREE"
          onClick={() => navigate("/onboarding")}
          clickable
          color="primary"
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 40,
          }}
        />
      </Box>
      <Box
        sx={{
          // padding: { xs: 1 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // borderRadius: 4,
          border: "1px solid black",
          borderColor: "divider",
          borderRadius: { xs: 5 },
        }}
      >
        <video
          width="100%"
          height="auto"
          ref={videoRef}
          controls
          // muted // Mute the video to allow autoplay
          onPause={handlePause}
          onPlay={handlePlay}
          style={{
            borderRadius: 40,
            objectFit: "contain",
            width: `${videoWidth}%`, // Use the videoWidth state here
            transition: "width 0.6s ease-in-out", // Smooth transition
          }}
        >
          <source src="/videos/dailydoc_launch.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );
}
