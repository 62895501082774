import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import DepartmentsTable from '../../components/departments/departmentsTable';
import DepartmentsForm from '../../components/departments/departmentsForm';

function DepartmentsPage() {
  return (
    <>
      <Container maxWidth='lg'>
        <PageHeading heading='Departments' />
        <DepartmentsForm />
        <DepartmentsTable />
      </Container>
    </>
  );
}

export default DepartmentsPage;
