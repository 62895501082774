import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DDLogo from "../icons/ddLogo";
import Box from "@mui/material/Box";

export default function Welcome() {
  const navigate = useNavigate();
  const steps = [
    { title: "Add departments", path: "/dashboard/departments" },
    { title: "Add Wards", path: "/dashboard/wards" },
    { title: "Add Beds", path: "/dashboard/beds" },
    {
      title: "Add user's mobile to User table",
      path: "/dashboard/access-control",
    },
    {
      title: "Register users on Daily Doc app",
      path: "/dashboard/instructions",
    },
  ];
  return (
    <Paper
      sx={{
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        background: "#448afc",
        color: "white",
        paddingTop: { xs: 2, sm: 3, md: 4 },
        paddingBottom: { xs: 2, sm: 3, md: 4 },
        paddingLeft: { xs: 2, sm: 3, md: 4 },
        paddingRight: { xs: 2, sm: 3, md: 4 },
        boxShadow: 8,
      }}
    >
      <Box>
        <Box mb={2}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
            <DDLogo />
            <Typography ml={2} variant="h5">
              Daily Doc
            </Typography>
          </Box>
          <Typography mb={1} variant="body1">
            Leader in Hospital communication.
          </Typography>
        </Box>
        <Box>
          <Typography mb={1} variant="h3">
            Get started
          </Typography>
          <Typography mb={1} variant="body1">
            Five easy steps. Click on the link for each.
          </Typography>
          <Grid container columnSpacing={2} rowSpacing={{ xs: 1 }} mb={5}>
            {steps.map((step, indx) => (
              <Grid key={step.title} size={{ xs: 12, sm: "auto" }}>
                <Button
                  onClick={() => navigate(`${step.path}`)}
                  sx={{
                    textAlign: "left",
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    color: "#fff",
                    padding: 0,
                    "&:hover": { color: "#111827" },
                  }}
                >
                  {indx + 1}. {step.title}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Typography variant="body1" mb={2} sx={{ color: "neutral.900" }}>
            Review detailed instructions on how to use Daily Doc app by using
            link below.
          </Typography>
          <Button
            onClick={() => navigate("instructions")}
            variant="contained"
            sx={{ width: 250, height: 50 }}
          >
            Review steps to get started
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
