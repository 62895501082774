import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";

import MobileEmail from "./MobileEmail";
import DDLogo from "../icons/ddLogo";
import MobileEmailOtp from "./MobileEmailOtp";
import SelectHospital from "./SelectHospital";
import api from "../../utilities/api";
import setAuthToken from "../../utilities/setAuthToken";
import {
  loginSuccessAction,
  loginFailureAction,
  loadHospitalProfilesAction,
} from "../../redux/auth/authAction";
import {
  loadObUserAction,
  // loadObHospitalAction,
  // loadObuserProfileAction,
} from "../../redux/onboarding/onboardingAction";
import { countries } from "../../utilities/countries";

const StyledLoginPaper = styled(Paper)(({ theme }) => ({
  width: 250,
  height: 350,
  borderRadius: 2,
  // to setup up background image use following syntax
  backgroundImage: `url('https://picsum.photos/300/300') `,
}));

const pages = ["Enter Mobile Or Email", "Enter OTP"];

const initialValues = {
  me: "",
  country: countries[103],
  otp: "",
  password: "",
};

const mobileEmailValidation = yup.object({
  me: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const otpValidation = yup.object({
  otp: yup.string().required("Required"),
});

const validationSchema = [mobileEmailValidation, otpValidation];

export default function Login() {
  const [manyProfiles, setmanyProfiles] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [OTPId, setOTPId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, isAuthenticatedToSeeHospitalProfiles, hospital } =
    auth;

  const onboarding = useSelector((state) => state.onboarding);
  const { isObAuthenticated, obUser } = onboarding;

  const isLastPage = currentStep === pages.length - 1;
  const backStep = (step) => {
    setCurrentStep(step - 1);
  };

  function getEmailOrMobileObj(mobileOrEmail, pass, code) {
    const isText = isNaN(mobileOrEmail);
    if (isText) {
      return {
        email: mobileOrEmail,
        password: pass,
        type: "web",
      };
    } else {
      return {
        mobile: mobileOrEmail,
        countryCode: code,
        password: pass,
        type: "web",
      };
    }
  }

  useEffect(() => {
    let isMounted = true; // Track if component is still mounted
    const getGeoInfo = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        if (isMounted && response) {
          const index = countries
            .map((object) => object.code)
            .indexOf(response.data.country_code);
          formik.setFieldValue("country", countries[index]);
        }
      } catch (err) {
        if (isMounted) formik.setFieldValue("country", countries[103]);
      }
    };
    getGeoInfo();
    // Cleanup function to prevent state update on unmount
    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async (values, actions) => {
    const { me, password, country, otp } = values;

    if (currentStep === 0) {
      const formData1 = {
        user: getEmailOrMobileObj(me, password, country.phone),
      };
      try {
        const response = await api.post("/auth/login", formData1);
        const { data } = response.data;
        const { otpId } = data;
        setOTPId(otpId);
        actions.setTouched({});
        actions.setSubmitting(false);
        setCurrentStep(currentStep + 1);
      } catch (err) {
        if (err) {
          actions.setFieldError("password", err.response?.data.message);
          dispatch(loginFailureAction());
        }
      }
    }
    if (currentStep === 1) {
      const formData2 = {
        otpData: {
          otpId: OTPId,
          otp: otp,
        },
      };
      try {
        const response = await api.post("/auth/verify/otp/web", formData2);
        const { data } = response.data;
        const { token, hospital } = data;
        const { userProfile, multipleProfile } = hospital;

        setAuthToken(token);

        if (!multipleProfile && userProfile?.webOnboarded) {
          dispatch(loginSuccessAction(data));
          navigate("/dashboard");
        }
        if (!multipleProfile && !userProfile?.webOnboarded) {
          dispatch(loadObUserAction(data?.hospital?.user));
          navigate("/edit-onboarding");
        }

        if (multipleProfile) {
          setmanyProfiles(multipleProfile);
          dispatch(loadHospitalProfilesAction(data));
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("otp", err.response?.data?.message);
          dispatch(loginFailureAction());
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[currentStep],
    onSubmit: onSubmit,
  });

  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <MobileEmail formik={formik} />;
      case 1:
        return (
          <MobileEmailOtp
            backStep={backStep}
            formik={formik}
            currentStep={currentStep}
          />
        );

      default:
        return <div>Page Not Found</div>;
    }
  }

  return (
    <>
      {isAuthenticatedToSeeHospitalProfiles && manyProfiles ? (
        <SelectHospital />
      ) : (
        <>
          {isAuthenticated &&
            !manyProfiles &&
            hospital?.userProfile?.webOnboarded && (
              <Navigate to="/dashboard" replace={true} />
            )}
          {isObAuthenticated && obUser && (
            <Navigate to="/edit-onboarding" replace={true} />
          )}
          <Box
            sx={{
              paddingTop: { xs: 1, sm: 2, md: 4 },
            }}
          >
            <Box
              sx={{
                margin: "auto",
                // maxWidth: 900,
                backgroundColor: "#fff",
                boxShadow: "0px 2px 30px #ccc6",
                borderRadius: 1,
              }}
            >
              <Grid container columnSpacing={2}>
                <Grid
                  size={{ xs: 12, sm: 6, md: 7 }}
                  // p={4}
                  sx={{
                    height: "auto",
                    background: "#fff",
                    padding: { xs: 3, sm: 3, md: 6 },
                    borderRadius: 5,
                    transition: "all .1s ease-in-out",
                    borderRight: "2px solid #f1f1f1",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      "& > :not(style)": {
                        mr: 2,
                        fontSize: "10",
                      },
                    }}
                  >
                    <DDLogo inheritViewBox />
                    <Typography variant="h6" color="primary.main">
                      Daily Doc
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginBottom: 3,
                      marginTop: 2,
                    }}
                  >
                    <Typography variant="h6">Login</Typography>
                  </Box>
                  <Box
                    sx={{
                      marginBottom: 10,
                      marginTop: 5,
                    }}
                  >
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                      {renderPageContent(currentStep)}
                      <Box
                        sx={{
                          display: "flex",
                          marginTop: 3,
                          marginBotton: 3,
                          gap: 2,
                        }}
                      >
                        {isLastPage && currentStep > 0 && (
                          <Button
                            variant="outlined"
                            fullWidth
                            size="large"
                            type="button"
                            onClick={() => backStep(currentStep)}
                            className="button button-green"
                          >
                            Back
                          </Button>
                        )}
                        <Box sx={{ width: "100%", position: "relative" }}>
                          <Button
                            variant="contained"
                            fullWidth
                            size="large"
                            type="submit"
                            disabled={formik.isSubmitting}
                          >
                            {isLastPage ? "Submit" : "Next"}
                          </Button>
                          {formik.isSubmitting && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </form>
                  </Box>
                  <Box
                    sx={{
                      marginTop: 5,
                      textAlign: "center",
                      color: "primary",
                    }}
                  >
                    <Link
                      href="/forgot-password"
                      color="primary"
                      variant="body2"
                      underline="hover"
                    >
                      Forgot password?
                    </Link>
                    <Box
                      sx={{
                        ...(currentStep > 0 && { display: "none" }),
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Do not have account?
                      </Typography>
                      <Link
                        href="/onboarding"
                        m={1}
                        color="primary"
                        variant="body2"
                        underline="hover"
                      >
                        Signup
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  size={{ xs: 0, sm: 6, md: 5 }}
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    height: "auto",
                    padding: { xs: 4, sm: 3, md: 6 },
                    backgroundColor: "#fff",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <StyledLoginPaper />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
