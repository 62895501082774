import ChangePassword from "../../components/change-password/ChangePassword";
import Container from "@mui/material/Container";

function ChangePasswordPage() {
  return (
    <Container maxWidth="lg">
      <ChangePassword />
    </Container>
  );
}

export default ChangePasswordPage;
