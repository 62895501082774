import Container from "@mui/material/Container";
import PageHeading from "../../components/reusables/PageHeading";
import EmployeeTable from "../../components/employees/EmployeeTable";
import EmployeeToolbar from "../../components/employees/EmployeeToolbar";

function EmployeesPage() {
  return (
    <Container maxWidth="lg">
      <PageHeading heading="Registered users" />
      <EmployeeToolbar />
      <EmployeeTable />
    </Container>
  );
}

export default EmployeesPage;
