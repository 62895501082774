import { useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid2";
import CardContent from "@mui/material/CardContent";
import LockResetIcon from "@mui/icons-material/LockReset";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import api from "../../utilities/api";

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const validationSchema = yup.object({
  oldPassword: yup.string().required("Required"),
  password: yup
    .string("Enter your password")
    .matches(
      passwordRegex,
      "Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required"
    )
    .required("Required"),

  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function ChangePassword() {
  const [showOldPassword, setshowOldPassword] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const hospital = useSelector((state) => state.auth.hospital);

  const handleClickShowOldPassword = () => {
    setshowOldPassword(!showOldPassword);
  };
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (values, actions) => {
    const url = `/hospitals/${hospital?._id}/users/${user?._id}`;
    const formData = {
      user: {
        changePassword: true,
        oldPassword: values.oldPassword,
        password: values.password,
        confirmPassword: values.confirmPassword,
      },
    };
    try {
      const response = api.patch(url, formData);
      if (response) {
        actions.resetForm(initialValues);
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError("confirmPassword", err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleClickCancel = () => {
    formik.resetForm(initialValues);
  };
  return (
    <Card sx={{ marginTop: 2, padding: { xs: 1, sm: 4 } }}>
      <CardHeader
        avatar={<LockResetIcon sx={{ fontSize: 40 }} color="secondary" />}
        title="Change Password"
      />
      <CardContent>
        <StyledForm onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container spacing={{ xs: 2 }}>
            <Grid size={{ xs: 12 }}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="oldPassword"
                  label="Old Password"
                  type={showOldPassword ? "text" : "password"}
                  value={formik.values.oldPassword}
                  name="oldPassword"
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  error={
                    formik.touched.oldPassword &&
                    Boolean(formik.errors.oldPassword)
                  }
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <FormHelperText error id="oldPassword-error">
                    {formik.errors.oldPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="password">New Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="password"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  name="password"
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                />
                {formik.touched.password && formik.errors.password && (
                  <FormHelperText error id="password-error">
                    {formik.errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formik.values.confirmPassword}
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <FormHelperText error id="confirmPassword-error">
                      {formik.errors.confirmPassword}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 6 }}>
              <Button
                size="large"
                fullWidth
                type="submit"
                color="primary"
                variant="outlined"
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Button
                size="large"
                onClick={handleClickCancel}
                fullWidth
                color="secondary"
                variant="outlined"
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
      </CardContent>
    </Card>
  );
}
