import Container from "@mui/material/Container";
import GettingStarted from "../../components/instructions/GettingStarted";
import AddUserInstructions from "../../components/instructions/AddUserInstructions";

export default function AddUserInstructionPage() {
  return (
    <Container maxWidth="lg">
      <AddUserInstructions />
    </Container>
  );
}
