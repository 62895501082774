import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

// for autocomplete

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EmailRolePage({
  formik,
  resetMobile,
  adminPanelRoles,
  adminName,
}) {
  const { departmentsList } = useSelector((state) => state.departments);
  const isAdmin = () => {
    if (formik.values.adminPanelRole === "613b5f785c768a51d90f295c") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Stack spacing={4}>
        <Box
          sx={{
            border: "1px solid #E6E8F0",
            height: 60,
            borderRadius: 1,
            backgroundColor: "background.default",
            display: "flex",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography mt={2}> Mobile: {formik?.values?.phone}</Typography>
            <Typography mb={2}>
              {adminName
                ? ` User: ${adminName}`
                : "You are adding outside user"}
            </Typography>
          </Box>
          <IconButton
            // size='small'
            sx={{ mx: 1 }}
            onClick={resetMobile}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <EmailIcon />
          //     </InputAdornment>
          //   ),
          // }}
        />
        <FormControl
          fullWidth
          error={
            formik.touched.adminPanelRole &&
            Boolean(formik.errors.adminPanelRole)
          }
        >
          <InputLabel id="myAminPanelRole">Role</InputLabel>
          <Select
            labelId="myAdminPanelRole"
            id="adminPanelRole"
            name="adminPanelRole"
            value={formik.adminPanelRole}
            label="Select Role"
            onChange={formik.handleChange}
            defaultValue={""}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {adminPanelRoles.map((role) => (
              <MenuItem key={role._id} value={role._id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.adminPanelRole && formik.errors.adminPanelRole}
          </FormHelperText>
        </FormControl>

        <Autocomplete
          multiple
          disabled={isAdmin()}
          value={formik.values.departments}
          id="departments"
          name="departments"
          options={departmentsList || []}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            formik.setFieldValue("departments", newValue);
          }}
          style={{
            display: isAdmin() ? "none" : null,
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          //   style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Departments"
              placeholder="Search Department"
              error={
                formik.touched.departments && Boolean(formik.errors.departments)
              }
              helperText={
                formik.touched.departments && formik.errors.departments
              }
            />
          )}
        />
      </Stack>
    </>
  );
}
