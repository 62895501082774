import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import EmailIcon from "@mui/icons-material/Email";
import api from "../../utilities/api";

import { styled } from "@mui/system";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { Typography } from "@mui/material";

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  name: "",
  email: "",
  contactNo: "",
  company: "",
  message: "",
};

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
  contactNo: yup.string().required("Required"),
  company: yup.string().required("Required"),
});

export default function ContactForm() {
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      contact: {
        name: values.name,
        email: values.email,
        mobile: values.contactNo,
        companyName: values.company,
        message: values.message,
      },
    };

    try {
      const url = "query";
      const response = await api.post(url, formData);
      if (response) {
        actions.resetForm({
          values: initialValues,
        });
        actions.setStatus({ success: true });
        dispatch(showSnackbarAction(response?.data?.message, "success"));
      }
    } catch (err) {
      actions.setStatus({ success: false });
      dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          marginBottom: 2,
          fontSize: {
            xs: "2rem", // For extra small screens (mobile)
            sm: "3rem", // h2 default size for all other screens
          },
        }}
      >
        Contact
      </Typography>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 3,
        }}
      >
        <Typography>Thank you for contacting Daily Doc</Typography>
        <Typography>Get hold of us on WhatsApp</Typography>
        <Typography>+1 6124225444</Typography>
        <Typography>or send us a message using following form</Typography>
      </Box>
      <Paper
        sx={{
          // padding: { xs: 1, sm: 4 },
          paddingLeft: { xs: 2, sm: 5 },
          paddingRight: { xs: 2, sm: 5 },
          paddingTop: { xs: 3, sm: 5 },
          paddingBottom: { xs: 3, sm: 5 },
          width: 600,
          maxWidth: "100%",
          borderRadius: 5,
        }}
      >
        <Box sx={{ marginBottom: 4, textAlign: "center" }}>
          <Typography variant="h6">Contact form</Typography>
        </Box>
        <StyledForm
          className="ward-form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <TextField
            sx={{ marginBottom: 2 }}
            fullWidth
            id="name"
            name="name"
            label="Name*"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            fullWidth
            id="email"
            name="email"
            label="Email*"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            fullWidth
            id="contactNo"
            name="contactNo"
            label="Phone Number*"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.contactNo}
            onChange={formik.handleChange}
            error={formik.touched.contactNo && Boolean(formik.errors.contactNo)}
            helperText={formik.touched.contactNo && formik.errors.contactNo}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            fullWidth
            id="company"
            name="company"
            label="Hospital*"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            multiline
            rows={5}
            fullWidth
            id="message"
            name="message"
            label="Message*"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
          <Box>
            <Button
              fullWidth
              type="submit"
              startIcon={<EmailIcon />}
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              Send
            </Button>
          </Box>
        </StyledForm>
      </Paper>
    </Box>
  );
}
