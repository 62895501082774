import { useEffect } from "react";
import Box from "@mui/material/Box";
import AboutUs from "../../components/about-us/AboutUs";

export default function AboutUsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <AboutUs />
    </Box>
  );
}
