import { Box, Container } from "@mui/material";
import ContactForm from "../../components/contact/ContactForm";
import { useEffect } from "react";

export default function ContactPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <ContactForm />
    </Box>
  );
}
