import Container from "@mui/material/Container";
import CurrentAdmins from "../../components/current-admins/CurrentAdmins";

export default function CurrentAdminsPage() {
  return (
    <Container maxWidth="lg">
      <CurrentAdmins />
    </Container>
  );
}
