import { useState, useCallback, useMemo, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Box from "@mui/system/Box";
import ObBed from "./ObBed";
import EditObBed from "./EditObBed";
import DialogAlert from "../alerts/DialogAlert";

export default function ObBedsPage({ formik }) {
  const [page, setPage] = useState(0);
  const [isBedsDialogOpen, setIsBedsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState("");
  const [bedErr, setBedErr] = useState("");

  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      const wardToEdit = formik.values.wards.find(
        (item) => item.name === row?.wardName
      );
      formik.setFieldValue("selectedWard", wardToEdit);
      formik.setFieldValue("bed", row?.bedName);
      setIsEditDialogOpen(true);
    },
    []
  );

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setIsDeleteDialogOpen(true);
    },
    []
  );

  //   delete bed
  const deleteBed = () => {
    formik.setFieldValue(
      "beds",
      formik.values.beds.filter((bed) => bed.id !== rowToDelete?.id)
    );
    setIsDeleteDialogOpen(false);
  };

  // close dialoge
  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
    setBedErr("");
    formik.setFieldValue("bed", "");
    formik.setFieldValue("selectedWard", null);
  };
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setdeleteErr("");
  };

  const columns = useMemo(
    () => [
      {
        field: "wardName",
        headerName: "Ward",
        description: "Ward",
        type: "string",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "bedName",
        headerName: "Bed",
        description: "Bed",
        type: "string",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [openDeleteDialog, editRow]
  );

  return (
    <Box>
      <Typography mb={1}>
        Add beds in your hospital by clicking on +Add beds button. Add one bed
        at a time.
      </Typography>
      <Typography mb={2}>
        One can add more beds through admin panel later on as well.
      </Typography>
      <Button
        sx={{ marginBottom: 3 }}
        color="primary"
        variant="contained"
        type="button"
        startIcon={<AddIcon />}
        onClick={() => setIsBedsDialogOpen(true)}
      >
        {formik.values.beds.length > 0 ? "Add more beds" : "Add bed"}
      </Button>
      {formik.touched.beds && Boolean(formik.errors.beds) && (
        <Box sx={{ color: "error.main" }}>{formik.errors.beds}</Box>
      )}
      {formik.values.beds.length > 0 && (
        <>
          <Typography mb={1}>Beds table</Typography>
          <Box
            sx={{
              // height: 400,
              width: "100%",
            }}
          >
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                padding: 1,
                border: 1,
                borderColor: "divider",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "wardName", sort: "asc" }],
                },
              }}
              columns={columns}
              rows={formik.values.beds}
              //pagination
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={5}
              rowsPerPageOptions={[5]}
              pagination
            />
          </Box>
        </>
      )}
      <ObBed
        open={isBedsDialogOpen}
        formik={formik}
        onClose={() => {
          setIsBedsDialogOpen(false);
          setBedErr("");
          formik.setFieldValue("bed", "");
          formik.setFieldValue("selectedWard", null);
        }}
        bedErr={bedErr}
        setBedErr={setBedErr}
      />
      <EditObBed
        open={isEditDialogOpen}
        formik={formik}
        onClose={closeEditDialog}
        rowToEdit={rowToEdit}
        setRowToEdit={setRowToEdit}
        bedErr={bedErr}
        setBedErr={setBedErr}
      />
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteBed}
        title={`Delete: ${rowToDelete?.name}`}
        msg={
          deleteErr ||
          `Are you sure you want to delete bed- ${rowToDelete?.name}?`
        }
      />
    </Box>
  );
}
