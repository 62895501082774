import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import RunCircleIcon from '@mui/icons-material/RunCircle';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import { loadAllAdminsAction } from '../../redux/admins/adminAction';
import PlanOptions from './PlanOptions';
import CalculatePrice from './CalculatePrice';
import PaymentIcon from '@mui/icons-material/Payment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import api from '../../utilities/api';
import { loadSubscriptionData } from '../../redux/subscription/subscriptionAction';

const StyledForm = styled('form')({
  width: '100%',
  maxWidth: '100%',
});

const pages = ['Select plan & number of users', 'Estimated cost'];

const planValidation = yup.object({
  plan: yup.string('Choose a plan').required('Required'),
  users: yup
    .number('Enter users')
    .required('Required')
    .min(10, 'Signup for minimum 10 people')
    .nullable(),
});
const promoCodeValidation = yup.object({
  promoCode: yup.string(),
  years: yup.number().required(),
});

const validationSchema = [planValidation, promoCodeValidation];

export default function RenewPlanForm({ handleClose, type }) {
  const [calculatedPriceData, setCalculatedPriceData] = useState();

  const sub = useSelector((state) => state.subscription.hospital);

  const initialValues = {
    plan: type !== 'CHANGE_PLAN' ? sub?.subscription?.plan?._id : '',
    users: sub?.subscription?.usersCount || 100,
    promoCode: '',
    years: 1,
  };
  const [currentStep, setCurrentStep] = useState(0);

  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.auth.hospital);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {}, [hospital]);

  const isLastPage = currentStep === pages.length - 1;
  const backStep = (step) => {
    setCurrentStep(step - 1);
  };

  const onSubmit = async (values, actions) => {
    const { plan, users, years } = values;

    if (currentStep === 0) {
      actions.setTouched({});
      actions.setSubmitting(true);

      let formData = {
        usersCount: users,
        hospital: hospital?._id,
        planId: plan,
        durationInYear: years,
        userId: user?._id,
      };
      let url = `/subscriptions/renew/step1`;
      if (type === 'ADD_USER') {
        url = `/subscriptions/addUser/step1`;
        formData = {
          additionalUsers: users,
          hospitalId: hospital?._id,
          planId: plan,
          durationInYear: formik?.values?.years,
          userId: user?._id,
        };
      }

      if (type === 'CHANGE_PLAN') {
        url = `/subscriptions/changeplan/step1`;
        formData = {
          usersCount: users,
          hospital: hospital?._id,
          planId: plan,
          durationInYear: years,
          userId: user?._id,
        };
      }

      try {
        const response = await api.post(url, formData);
        if (response) {
          actions.setTouched({});
          actions.setSubmitting(false);
          setCalculatedPriceData(response?.data?.data);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        dispatch(showSnackbarAction(err?.response?.data?.message, 'error'));
      }
    }

    if (currentStep === 1) {
      if (calculatedPriceData?.isSubscriptionPaid) {
        window.location.href = calculatedPriceData?.redirectUrl;
      } else {
        const formData = {
          _id: calculatedPriceData?.calculatePriceData?._id,
        };

        let url = `/subscriptions/renew/step2`;

        if (type === 'ADD_USER') {
          url = `/subscriptions/addUser/step2/free`;
        }

        if (type === 'CHANGE_PLAN') {
          url = `/subscriptions/changeplan/step2/free`;
        }
        console.log({ url });
        try {
          const response = await api.post(url, formData);
          if (response) {
            dispatch(loadSubscriptionData(response?.data?.data?.hospital));
            formik.resetForm({
              values: initialValues,
            });
            handleClose();
          }
        } catch (err) {
          actions.setFieldError('users', err.response?.data.message);
        }
      }
    }
  };

  const applyPromoCode = async () => {
    try {
      let formData = {
        usersCount: calculatedPriceData?.calculatePriceData?.usersCount,
        hospital: hospital?._id,
        planId: calculatedPriceData?.calculatePriceData?.planId,
        durationInYear: formik?.values?.years,
        userId: user?._id,
      };

      let url = `/subscriptions/renew/step1`;

      if (type === 'ADD_USER') {
        url = `/subscriptions/addUser/step1`;
        formData = {
          additionalUsers: formik?.values?.users,
          hospitalId: hospital?._id,
          planId: formik?.values?.plan,
          promoCode: formik?.values?.promoCode,
          durationInYear: formik?.values?.years,
          userId: user?._id,
        };
      }

      if (type === 'RENEW') {
        formData = {
          usersCount: calculatedPriceData?.calculatePriceData?.usersCount,
          hospital: hospital?._id,
          planId: calculatedPriceData?.calculatePriceData?.planId,
          durationInYear: formik?.values?.years,
          userId: user?._id,
          promoCode: formik?.values?.promoCode,
        };
      }
      if (type === 'CHANGE_PLAN') {
        url = `/subscriptions/changeplan/step1`;
        formData = {
          usersCount: calculatedPriceData?.calculatePriceData?.usersCount,
          hospital: hospital?._id,
          planId: calculatedPriceData?.calculatePriceData?.planId,
          durationInYear: formik?.values?.years,
          userId: user?._id,
          promoCode: formik?.values?.promoCode,
        };
      }

      const response = await api.post(url, formData);
      if (response) {
        formik.setFieldValue('promoCode', '');
        setCalculatedPriceData(response?.data?.data);
      }
    } catch (err) {
      dispatch(showSnackbarAction(err?.response?.data?.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[currentStep],
    onSubmit: onSubmit,
  });

  const resetPlan = () => {
    formik.resetForm({
      values: initialValues,
    });
    setCurrentStep(0);
  };

  let { plans } = useSelector((state) => state.subscription);

  if (sub?.subscription?.plan?._id && type === 'CHANGE_PLAN') {
    plans = plans?.filter(
      (plan) => plan?._id.toString() !== sub?.subscription?.plan?._id.toString()
    );
  }
  console.log({ plans });
  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <PlanOptions type={type} plans={plans} formik={formik} />;
      case 1:
        return (
          <CalculatePrice
            type={type}
            applyPromocode={applyPromoCode}
            priceData={calculatedPriceData?.calculatePriceData}
            formik={formik}
          />
        );

      default:
        return <div>Page Not Found</div>;
    }
  }

  return (
    <Paper
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        px: { xs: 1, sm: 3, md: 5 },
        py: 8,
      }}
    >
      <Box>
        <StyledForm onSubmit={formik.handleSubmit} autoComplete="off">
          {renderPageContent(currentStep)}
          <Box
            sx={{
              display: 'flex',
              marginTop: 6,
              marginBotton: 3,
              gap: 2,
              justifyContent: 'flex-end',
            }}
          >
            {currentStep > 0 && (
              <Button
                startIcon={<ArrowBackIcon />}
                variant="outlined"
                // fullWidth
                type="button"
                onClick={() => backStep(currentStep)}
                className="button button-green"
              >
                Back
              </Button>
            )}
            <Box sx={{ position: 'relative' }}>
              <Button
                size="large"
                variant="contained"
                fullWidth
                type="submit"
                disabled={formik.isSubmitting}
                endIcon={
                  calculatedPriceData?.isSubscriptionPaid && isLastPage ? (
                    <PaymentIcon />
                  ) : (
                    <NavigateNextIcon />
                  )
                }
              >
                {calculatedPriceData?.isSubscriptionPaid && isLastPage
                  ? 'Make Payment'
                  : !isLastPage
                  ? 'Next'
                  : 'Submit'}
              </Button>
              {formik.isSubmitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
          </Box>
        </StyledForm>
      </Box>
    </Paper>
  );
}
