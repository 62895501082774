import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadDefaultDepartmentsAction } from "../../redux/onboarding/onboardingAction";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import api from "../../utilities/api";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function DepartmentsPage({ formik }) {
  const dispatch = useDispatch();
  const { defaultDepartments } = useSelector((state) => state.onboarding);

  const loadDefaultDepartments = () => {
    api
      .get("/onboarding/ob4/departments")
      .then((res) => {
        dispatch(loadDefaultDepartmentsAction(res.data.data));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!defaultDepartments?.length > 0) {
      loadDefaultDepartments();
    }
  }, []);

  return (
    defaultDepartments &&
    defaultDepartments.length > 0 && (
      <FormControl sx={{ m: 1, width: "100%" }}>
        <Autocomplete
          multiple
          value={formik.values.departments}
          id="departments"
          name="departments"
          options={defaultDepartments || []}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => {
            return option?.name === value.name;
          }}
          onChange={(event, newValue) => {
            formik.setFieldValue("departments", newValue);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          //   style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Departments"
              value={formik.values.departments}
              placeholder="Search Department"
              error={
                formik.touched.departments && Boolean(formik.errors.departments)
              }
              helperText={
                formik.touched.departments && formik.errors.departments
              }
            />
          )}
        />

        <Typography mt={3}>
          Choose all the departments available in the hospital. This can be
          edited from the admin panel
        </Typography>
      </FormControl>
    )
  );
}

export default DepartmentsPage;
