import Drawer from "@mui/material/Drawer";
import {
  drawerWidth,
  drawerBackground,
  drawerTextColor,
} from "./utilAminNavbar";
import DrawerList from "./DrawerList";

function AdminDrawer({ handleDrawerClose }) {
  return (
    <>
      <Drawer
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: drawerBackground,
            color: drawerTextColor,
          },
        }}
        variant="permanent"
        anchor="left"
        open
      >
        <DrawerList handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </>
  );
}

export default AdminDrawer;
