import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import FeatureItem from "./FeatureItem";
import { features } from "../subscription/util";
import Stack from "@mui/material/Stack";
import { Chip, Divider } from "@mui/material";
import { useState, useEffect } from "react";

const Column = styled(Paper)(({ theme }) => ({
  maxWidth: 360,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: 40,
  backgroundColor: "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

export default function AllFeatures() {
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);

  const x = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    const chunkSize = Math.floor(features?.length / 2);
    // create copies of features array
    const copyArray1 = features.slice();
    const copyArray2 = features.slice();
    setList1(copyArray1?.splice(0, chunkSize));
    setList2(copyArray2?.splice(chunkSize, chunkSize + 1));
  }, []);

  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 5,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            marginBottom: 2,
            fontSize: {
              xs: "2rem", // For extra small screens (mobile)
              sm: "3rem", // h2 default size for all other screens
            },
          }}
        >
          ..More Features
        </Typography>
        <Typography>
          We are constantly adding new features to Daily Doc. This is the list
          of all features offered by Daily Doc.
        </Typography>
        <Typography>
          Please reachout to us by using contact form if you specifically
          looking for something which is not listed here.
        </Typography>
        <Typography>
          Some of the features are offered in Enterprise plan only.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          // columnSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 5 }}
        >
          <Grid size={6}>
            <Column>
              <Stack direction="column" spacing={2} mb={5}>
                <Chip
                  label="includes"
                  size="small"
                  sx={{
                    backgroundColor: "rgb(235, 238, 243)",
                  }}
                />
                <Divider />
              </Stack>
              {list1?.map((feature) => (
                <FeatureItem key={feature.feature} feature={feature} />
              ))}
            </Column>
          </Grid>
          <Grid size={6}>
            <Column>
              <Stack direction="column" spacing={2} mb={5}>
                <Chip
                  label="includes"
                  size="small"
                  sx={{
                    backgroundColor: "rgb(235, 238, 243)",
                  }}
                />
                <Divider />
              </Stack>
              {list2?.map((feature) => (
                <FeatureItem key={feature.feature} feature={feature} />
              ))}
            </Column>
          </Grid>
        </Grid>
      </Box>
      {/* <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 5,
          borderRadius: 5,
        }}
      >
        <Grid
          container
          columnSpacing={2}
          rowSpacing={0.5}
          sx={{
            // border: "1px solid black",
            width: 800,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {features?.map((feature) => (
            <Grid size={6} key={feature?.feature}>
              <FeatureItem feature={feature} />
            </Grid>
          ))}
        </Grid>
      </Paper> */}
    </Box>
  );
}
