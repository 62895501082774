import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';

export default function SubscriptionActions({ openRenewPlanForm, setType }) {
  const { hospital } = useSelector((state) => state.subscription);
  return (
    <Box>
      <Grid container rowSpacing={4} columnSpacing={2}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Typography variant="h6">Expires on</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {new Date(hospital?.subscription?.endDate).toDateString()}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                openRenewPlanForm();
                setType('RENEW');
              }}
            >
              Renew Plan
            </Button>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Typography variant="h6">User Limit</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {hospital?.subscription?.plan?.userLimit}
            </Typography>
            <Button
              onClick={() => {
                openRenewPlanForm();
                setType('ADD_USER');
              }}
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
            >
              Add Users
            </Button>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Typography variant="h6">Upgrade Plan</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              anytime.
            </Typography>
            <Button
              onClick={() => {
                openRenewPlanForm();
                setType('CHANGE_PLAN');
              }}
              variant="contained"
            >
              Change Plan
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
