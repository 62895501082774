import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ConfirmRegistration from './ConfirmRegistration';

import { useFormik } from 'formik';
import * as yup from 'yup';

import AdminMobileVerification from './AdminMobileVerification';
import RegisterAdminDetails from './RegisterAdminDetails';

import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utilities/api';

const pages = ['Mobile Validation', 'Admin Details'];

const MobileValidationScehma = yup.object().shape({
  otp: yup.string().required('Required'),
});
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const AdminDetailsValidationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  password: yup
    .string()
    .required('Required')
    .matches(
      passwordRegex,
      'Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required'
    ),
  confirmPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const PaperElement = styled(Paper)(({ theme }) => ({
  width: 500,
  padding: 50,
}));

export default function RegisterAdmin() {
  const [currentStep, setCurrentStep] = useState(0);
  const [token, setToken] = useState('');
  const [data, setData] = useState();
  const [success, setSucces] = useState('');
  const [statusCode, setStatusCode] = useState(null);
  const [webOnboardedUser, setWebOnboardedUser] = useState(null);

  const { secret } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLastStep = currentStep === pages.length - 1;

  const onSubmit = async (values, actions) => {
    if (currentStep === 0) {
      const formData = {
        user: {
          verificationToken: token,
          otp: values.otp,
        },
      };

      try {
        const response = await api.post('auth/access/step3', formData);
        if (response) {
          setToken(response.data.data.token);
          setWebOnboardedUser(response?.data?.data?.user);
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setStatus({ success: false });
          actions.setFieldError('otp', err.response.data.message);
        }
      }
    }

    if (currentStep === 1) {
      let formData;
      if (webOnboardedUser?.webOnboarded) {
        formData = {
          user: {
            firstName: webOnboardedUser?.firstName,
            lastName: webOnboardedUser?.lastName,
          },
          token: token,
        };
      } else {
        formData = {
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            confirmPassword: values.password,
          },
          token: token,
        };
      }

      try {
        let response = await api.post(`auth/access/step4`, formData);
        if (response) {
          dispatch(showSnackbarAction(response.data.message, 'success'));
          navigate('/login');
        }
      } catch (err) {
        if (err) {
          dispatch(showSnackbarAction(err.response.data.message, 'error'));
        }
      }
    }
  };

  const initialValues = {
    countryCode: data?.countryCode || '',
    mobile: data?.mobile || '',
    otp: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  };

  let validationSchema;
  if (webOnboardedUser?.webOnboarded) {
    validationSchema = [MobileValidationScehma];
  } else {
    validationSchema = [MobileValidationScehma, AdminDetailsValidationSchema];
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[currentStep],
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <AdminMobileVerification formik={formik} />;
        break;

      case 1:
        if (webOnboardedUser?.webOnboarded) {
          return <ConfirmRegistration />;
        } else {
          return <RegisterAdminDetails formik={formik} />;
        }
      default:
        break;
    }
  };

  const validateAdminLink = async () => {
    try {
      let response = await api.post(
        `/auth/access/otp?verificationToken=${secret}`
      );
      setData(response.data.data);
      setToken(response.data.data.token);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    validateAdminLink();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {statusCode === 200 ? (
        <PaperElement sx={{ textAlign: 'center' }}>
          <Typography
            sx={{ marginBottom: '24px', color: 'green' }}
          >{`${success} !!`}</Typography>
          <Button
            onClick={() => navigate('/login')}
            type="button"
            color="primary"
            variant="contained"
          >
            Login
          </Button>
        </PaperElement>
      ) : (
        <>
          <Typography mb={3} variant="h4">
            Register
          </Typography>
          <PaperElement elevation={1}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {renderStepContent(currentStep)}
              <Stack sx={{ marginTop: 5 }} direction="row-reverse" spacing={2}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={formik.isSubmitting}
                  type="submit"
                  endIcon={<NavigateNextIcon />}
                >
                  {isLastStep ? 'Submit' : 'Next'}
                </Button>
              </Stack>
            </form>
          </PaperElement>
        </>
      )}
    </Box>
  );
}
