import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import Typography from "@mui/material/Typography";
import WaveIcon from "../icons/WaveIcon";
import { Divider, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import HeroSketch from "../icons/heroSketch";
import WhatAreYouWaiting from "../home/WhatAreYouWaiting";
import doctorSketch from "../../assets/doctor-sketch.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 40,
  borderBottomRightRadius: 80,
  borderBottomLeftRadius: 80,
  backgroundColor: theme.palette.background.default,
}));

const Item = styled(Paper)(({ theme }) => ({
  height: 300,
  position: "relative",
  borderRadius: 40,
  boxShadow: theme.shadows[1],
  backgroundColor: "rgb(241, 244, 249)",
  ...theme.typography.subtitle2,
  [theme.breakpoints.down("sm")]: {
    height: 320,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: 360,
  // border: "1px solid black",
}));

export default function AboutUs() {
  const navigate = useNavigate();
  const chipsInChip = (
    <Box>
      <span
        style={{
          marginRight: 4,
        }}
      >
        Served Over 100+
      </span>
      <Chip
        avatar={
          <CelebrationOutlinedIcon
            sx={{
              "&.MuiChip-avatar": {
                color: (theme) => theme.palette.warning.light,
              },
            }}
          />
        }
        label="Hospitals"
        sx={{
          backgroundColor: "rgb(204, 229, 255)",
          height: "auto",
          "& .MuiChip-label": {
            marginLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 1,
            paddingRight: 1,
          },
        }}
      />
    </Box>
  );
  return (
    <Box>
      <Box
        sx={{
          marginBottom: 8,
        }}
      >
        <StyledPaper>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Box>
              <Chip
                sx={{
                  border: "1px solid rgb(226, 226, 229)",
                  height: "auto",
                  "& .MuiChip-label": {
                    padding: 0.5,
                  },
                }}
                variant="outlined"
                label={chipsInChip}
              />
            </Box>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: "2rem", // For extra small screens (mobile)
                    sm: "3rem", // h2 default size for all other screens
                  },
                }}
              >
                More than a company
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: "2rem", // For extra small screens (mobile)
                    sm: "3rem", // h2 default size for all other screens
                  },
                }}
              >
                It's commitment to excellence!
              </Typography>
            </Box>
            <Box>
              <WaveIcon />
            </Box>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography>
                Daily Doc is a product of Portamed Private Limited, Pune, India.
              </Typography>
              <Typography>
                Established in 2018, It was conceived to bring innovative
                technology in medicine.
              </Typography>
              <Typography>
                Our mission is to make patient care more efficient and improve
                medical outcomes.
              </Typography>
              <Typography>
                Honesty and Integrity are our core values.
              </Typography>
            </Box>
          </Stack>
        </StyledPaper>
      </Box>
      <Box mb={8}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2 }}
          rowSpacing={{ xs: 1, sm: 2 }}
        >
          <Grid size={6}>
            <Item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 0,
                    paddingLeft: { xs: 3, sm: 5 },
                    paddingRight: { xs: 3, sm: 5 },
                    paddingTop: { xs: 3, sm: 5 },
                  }}
                >
                  <PeopleOutlineIcon color="secondary" sx={{ fontSize: 40 }} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Paper
                    sx={{
                      height: "100%",
                      backgroundColor: "rgb(235, 238, 243)",
                      borderRadius: 5,
                      paddingLeft: { xs: 3, sm: 5 },
                      paddingRight: { xs: 3, sm: 5 },
                      paddingTop: { xs: 4, sm: 5 },
                      paddingBottom: { xs: 4, sm: 5 },
                    }}
                  >
                    <Typography
                      mb={2}
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: "1.2rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Teamwork
                    </Typography>
                    <Typography>
                      We focus on power of high performant & agile team.
                    </Typography>
                  </Paper>
                </Box>
              </Box>
            </Item>
          </Grid>
          <Grid size={6}>
            <Item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  borderRadius: 5,
                }}
              >
                <Box
                  sx={{
                    flexGrow: 0,
                    paddingLeft: { xs: 3, sm: 5 },
                    paddingRight: { xs: 3, sm: 5 },
                    paddingTop: { xs: 3, sm: 5 },
                  }}
                >
                  <SpaOutlinedIcon color="secondary" sx={{ fontSize: 40 }} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Paper
                    sx={{
                      height: "100%",
                      backgroundColor: "rgb(235, 238, 243)",
                      borderRadius: 5,
                      paddingLeft: { xs: 3, sm: 5 },
                      paddingRight: { xs: 3, sm: 5 },
                      paddingTop: { xs: 4, sm: 5 },
                      paddingBottom: { xs: 4, sm: 5 },
                    }}
                  >
                    <Typography
                      mb={2}
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: "1.2rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Growth
                    </Typography>
                    <Typography>
                      We promote professional growth & nuture skills.
                    </Typography>
                  </Paper>
                </Box>
              </Box>
            </Item>
          </Grid>
          <Grid size={12}>
            <Item>
              <Box
                sx={{
                  height: "100%",
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  justifyContent: "space-between",
                  paddingLeft: { xs: 3, sm: 5 },
                  paddingRight: { xs: 3, sm: 5 },
                  paddingTop: { xs: 4, sm: 5 },
                  paddingBottom: { xs: 4, sm: 5 },
                }}
              >
                <Box>
                  <Typography
                    mb={2}
                    variant="h4"
                    sx={{
                      fontSize: {
                        xs: "1.2rem",
                        sm: "2rem",
                      },
                    }}
                  >
                    Vison
                  </Typography>
                  <Typography>To bring modern techn in medicine</Typography>
                </Box>
                <Box>
                  <Chip
                    onClick={() => navigate("/contact")}
                    clickable
                    color="secondary"
                    label="Join community"
                    sx={{
                      borderRadius: 40,
                      paddingLeft: { xs: 0, sm: 5 },
                      paddingRight: { xs: 0, sm: 5 },
                      paddingTop: { xs: 2, sm: 2.5 },
                      paddingBottom: { xs: 2, sm: 2.5 },
                    }}
                  />
                </Box>
              </Box>
              <Paper
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: "60%",
                  backgroundColor: "rgb(235, 238, 243)",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <HeroSketch
                  className="hero-sketch"
                  circleFill="rgb(241, 244, 249)"
                />
              </Paper>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box mb={8}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            marginBottom: 5,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
            }}
          >
            Culture of innovation
          </Typography>
          <Box
            sx={{
              textAlign: "center",
              // width: 600,
              maxWidth: "100%",
            }}
          >
            <Typography>
              Designed by doctors and nurses, we strive to innovate and make our
              platform better every day.
            </Typography>
            <Typography>
              It is an immense joy to bring a positive meaningful change in
              peoples lives.
            </Typography>
            <Typography>
              We constantly add new features and improve our processes.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mb={8}>
        <Divider />
      </Box>
      <Box mb={8}>
        <Grid
          container
          // columnSpacing={8}
          // rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 2 }}
          rowSpacing={{ xs: 1, sm: 2 }}
        >
          <Grid size={4}>
            <StyledBox>
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgb(235, 238, 243)",
                  borderRadius: 5,
                }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt="doctorSketch img"
                  src={doctorSketch}
                />
              </Box>
            </StyledBox>
          </Grid>
          <Grid size={8}>
            <StyledBox
              sx={{
                display: "flex",
                flexDirection: "column",
                // gap: 4,
                gap: { xs: 2, sm: 4 },
                justifyContent: "space-between",
                // paddingTop: 3,
                // paddingBottom: 5,
                paddingTop: { xs: 1, sm: 3 },
                paddingBottom: { xs: 3, sm: 5 },
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      xs: "1.2rem",
                      sm: "2rem",
                    },
                    marginBottom: { xs: 1, sm: 3 },
                  }}
                >
                  Strive to lead
                </Typography>
                <Typography
                  sx={{
                    marginBottom: { xs: 1, sm: 2 },
                  }}
                >
                  Daily Doc is leader in hospital communication.
                </Typography>
                <Typography>
                  Daily Doc brings technology in today's complex medical
                  environment to empower healthcare providers to have
                  effortless, reliable and secure communication.
                </Typography>
              </Box>
              <Box>
                <Chip
                  onClick={() => navigate("/contact")}
                  clickable
                  color="secondary"
                  label="We Are Hiring"
                  sx={{
                    paddingLeft: { xs: 0, sm: 5 },
                    paddingRight: { xs: 0, sm: 5 },
                    paddingTop: { xs: 2, sm: 2.5 },
                    paddingBottom: { xs: 2, sm: 2.5 },
                    borderRadius: 40,
                  }}
                />
              </Box>
            </StyledBox>
          </Grid>
        </Grid>
      </Box>
      <Box mb={8}>
        <Divider />
      </Box>
      <Box mb={8}>
        <Typography
          mb={5}
          variant="h2"
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "2rem", // For extra small screens (mobile)
              sm: "3rem", // h2 default size for all other screens
            },
          }}
        >
          Get Daily Doc for secure messaging !!
        </Typography>
        <WhatAreYouWaiting />
      </Box>
    </Box>
  );
}
