import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Subscription from '../../components/subscription/Subscription';
import WhatAreYouWaiting from '../../components/home/WhatAreYouWaiting';
import { useEffect } from 'react';
import { progressBarStyle } from '../../utilities/commonUtil';
import { getPlansAndFeatures } from '../../components/subscription/util';

export default function PricePage() {
  const { features, plans, isLoading } = useSelector(
    (state) => state.subscription
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    getPlansAndFeatures();
  }, []);

  return isLoading ? (
    <Box>
      <CircularProgress sx={progressBarStyle} />
    </Box>
  ) : (
    <Box>
      <Box mb={5}>
        <Subscription features={features} planData={plans} />
      </Box>
      <WhatAreYouWaiting />
    </Box>
  );
}
