import React from 'react';

function DDLogo({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      // these are original icons sizes. Im downsizing for login page
      // width='55'
      // height='55'
      width='36'
      height='36'
      viewBox='0 0 55 55'
    >
      <g transform='translate(-0.5 -0.5)'>
        <g transform='translate(-125 -39)'>
          <g transform='translate(126 40)'>
            <rect
              width='54'
              height='54'
              rx='10'
              fill='#fff'
              stroke='#dcddeb'
              strokeWidth='1'
            />
            <g transform='translate(7.5 4)'>
              <g transform='translate(14.1)'>
                <path
                  d='M2.893-45.9a2.259,2.259,0,0,1-.8.123,1.982,1.982,0,0,0-.961.2A2.072,2.072,0,0,0,.016-43.361a21.817,21.817,0,0,0,.909,4.072,3.533,3.533,0,0,0,2.7,2.547l.39.064v.209c0,.2,0,.2.227.2a.468.468,0,0,1,.274.059c.017.032.017,7.219-.006,15.969L4.459-4.328l-.239.064a2.294,2.294,0,0,0-1.4,1.172,1.441,1.441,0,0,0-.181.888A1.768,1.768,0,0,0,3.225-.737,2.505,2.505,0,0,0,6.737-.7a1.92,1.92,0,0,0,.641-1.354,1.667,1.667,0,0,0-.1-.808A2.36,2.36,0,0,0,5.857-4.248l-.274-.091L5.6-20.286l.017-15.952.221-.016c.21-.016.215-.027.215-.22v-.2l.408-.086c1.508-.326,2.4-1.461,2.994-3.789.181-.712.489-2.317.536-2.815a2.276,2.276,0,0,0-.629-1.889,1.773,1.773,0,0,0-1.386-.476c-.5-.016-.641-.043-.78-.134a.906.906,0,0,0-.821-.016.817.817,0,0,0-.379.594.818.818,0,0,0,.379.594.918.918,0,0,0,.769,0,1.656,1.656,0,0,1,.646-.086c.53,0,.763.1.967.428.274.428.047,2.237-.53,4.174-.425,1.445-1.1,2.28-1.986,2.44-.181.032-.186.032-.186-.161v-.193H4.011v.187c0,.171-.017.187-.163.187a2.152,2.152,0,0,1-1.147-.6c-.617-.647-1-1.7-1.427-4-.268-1.424-.233-1.889.175-2.258l.221-.2h.542a1.522,1.522,0,0,1,.658.08.882.882,0,0,0,.39.08A.679.679,0,0,0,3.6-45.908.705.705,0,0,0,2.893-45.9Z'
                  transform='translate(0 46)'
                  fill='#343434'
                />
                <circle
                  cx='1.5'
                  cy='1.5'
                  r='1.5'
                  transform='translate(3.5 42.3)'
                  fill='#1aa6e1'
                />
              </g>
              <g transform='translate(0 9.5)'>
                <path
                  d='M28.66-26a17.78,17.78,0,0,1,5.113.753A13.306,13.306,0,0,1,38.3-22.9V-2.773a13.236,13.236,0,0,1-4.669,2.13A21.759,21.759,0,0,1,28.375,0H20.3V-26Zm-3.444,4V-4h3.018a12.047,12.047,0,0,0,3.036-.382A7.15,7.15,0,0,0,33.9-5.662a6.317,6.317,0,0,0,1.846-2.407,15.381,15.381,0,0,0,.692-4.893,15.423,15.423,0,0,0-.692-4.874A6.455,6.455,0,0,0,33.9-20.281,7.2,7.2,0,0,0,31.234-21.6a12.177,12.177,0,0,0-3.142-.4Z'
                  transform='translate(58.6) rotate(180)'
                  fill='#2a3181'
                />
                <path
                  d='M8.36,26a17.78,17.78,0,0,0,5.113-.753A13.306,13.306,0,0,0,18,22.9V2.773A13.236,13.236,0,0,0,13.331.643,21.758,21.758,0,0,0,8.076,0H0V26ZM4.915,22V4H7.933a12.047,12.047,0,0,1,3.036.382A7.15,7.15,0,0,1,13.6,5.662a6.317,6.317,0,0,1,1.846,2.407,15.381,15.381,0,0,1,.692,4.893,15.423,15.423,0,0,1-.692,4.874A6.455,6.455,0,0,1,13.6,20.281,7.2,7.2,0,0,1,10.934,21.6a12.178,12.178,0,0,1-3.142.4Z'
                  fill='#1aa6e1'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DDLogo;
