import {
  OBUSER_LOADED,
  OBHOSPITAL_LOADED,
  DEFAULT_DEPARTMENTS_LOADED,
  OBUSERPROFILE_LOADED,
  ONBOARDING_RESET,
  OBDEPARTMENTS_LOADED,
  OBWARDS_LOADED,
} from "./onboardingTypes";

export const loadObUserAction = (obUser) => (dispatch) => {
  dispatch({
    type: OBUSER_LOADED,
    payload: obUser,
  });
};

export const loadObHospitalAction = (obHospital) => (dispatch) => {
  dispatch({
    type: OBHOSPITAL_LOADED,
    payload: obHospital,
  });
};

export const loadDefaultDepartmentsAction = (departments) => (dispatch) => {
  dispatch({
    type: DEFAULT_DEPARTMENTS_LOADED,
    payload: departments,
  });
};

export const loadObuserProfileAction = (data) => (dispatch) => {
  dispatch({
    type: OBUSERPROFILE_LOADED,
    payload: data,
  });
};

export const loadObWardsAction = (wards) => (dispatch) => {
  dispatch({
    type: OBWARDS_LOADED,
    payload: wards,
  });
};

export const loadObDepartmentsAction = (departments) => (dispatch) => {
  dispatch({
    type: OBDEPARTMENTS_LOADED,
    payload: departments,
  });
};

export const resetOnboardingAction = () => (dispatch) => {
  dispatch({
    type: ONBOARDING_RESET,
  });
};
