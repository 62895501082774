import * as React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getEmployeeData } from "./utilEmployeeTable";
import Paper from "@mui/material/Paper";

import EditEmployeeForm from "./EditEmployeeForm";
import api from "../../utilities/api";
import DialogAlert from "../alerts/DialogAlert";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PauseCircleFilledOutlinedIcon from "@mui/icons-material/PauseCircleFilledOutlined";
import Tooltip from "@mui/material/Tooltip";

const validationSchema = yup.object().shape({
  firstName: yup.string("Provide Name").required("Required"),
  lastName: yup.string("Provide mobile number").required("Required"),
  inputRole: yup.string("Provide mobile number").required("Required"),
  inputDepartment: yup.string("Provide mobile number").required("Required"),
  inputDesignation: yup.string("Provide mobile number").required("Required"),
});

function EmployeeTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [isSuspendDialogOpen, setisSuspendDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [rowToSuspend, setRowToSuspend] = useState([]);

  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.auth.hospital);
  const {
    employeesData,
    defaultRoles,
    hospitalDepartments,
    defaultDesignations,
  } = useSelector((state) => state.employees);

  useEffect(() => {
    getEmployeeData(hospital._id);
    return () => {};
  }, [hospital]);

  // for formik set up
  const onSubmit = async (values, actions) => {
    try {
      const formData = {
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          hospitalRole: values.role._id,
          hospitalDesignation: values.designation._id,
          department: values.department._id,
        },
      };
      const url = `/hospitals/${hospital._id}/users/${rowToEdit?._id}`;
      const response = await api.patch(url, formData);
      if (response) {
        getEmployeeData(hospital._id);
        setisEditDialogOpen(false);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const formik = useFormik({
    initialValues: {
      employeeId: rowToEdit.id || "",
      firstName: rowToEdit.firstName || "",
      lastName: rowToEdit.lastName || "",
      role:
        defaultRoles.find((e) => e.name === rowToEdit?.hospitalRole) ||
        defaultRoles[0],
      department:
        hospitalDepartments.find((e) => e.name === rowToEdit?.department) ||
        hospitalDepartments[0],
      designation:
        defaultDesignations.find((e) => e.name === rowToEdit?.designation) ||
        defaultDesignations[0],
      inputRole: rowToEdit.role || "",
      inputDepartment: rowToEdit.department || "",
      inputDesignation: rowToEdit.designation || "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  // close dialoge

  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete([]);
  };
  const closeSuspendDialog = () => {
    setisSuspendDialogOpen(false);
    setRowToSuspend([]);
  };

  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );
  // delete user

  const deleteUser = async () => {
    try {
      const url = `/hospitals/${hospital._id}/users`;
      const formData = {
        users: [rowToDelete?._id],
      };
      const response = await api.delete(url, { data: formData });
      if (response) {
        getEmployeeData(hospital._id);
        setisDeleteDialogOpen(false);
      }
    } catch (err) {
      if (err) dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const openSuspendDialog = useCallback(
    (id, row) => () => {
      setRowToSuspend(row);
      setisSuspendDialogOpen(true);
    },
    []
  );

  const suspendUser = () => {};

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "DD-ID",
        description: "Daily Doc ID",
        type: "string",
        minWidth: 80,
        flex: 0.5,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        description: 'Employee"s Full Name',
        type: "string",
        minWidth: 150,
        flex: 1,
      },

      {
        field: "countryCode",
        headerName: "Code",
        description: "Country Code for mobile",
        type: "string",
        minWidth: 80,
        flex: 0.5,
      },
      {
        field: "mobile",
        headerName: "Mobile",
        description: "Employee mobile number",
        type: "string",
        minWidth: 120,
        flex: 1,
      },
      {
        field: "hospitalRole",
        headerName: "Role",
        description: "Role",
        type: "string",
        minWidth: 120,
        flex: 1,
      },
      {
        field: "department",
        headerName: "Department",
        description: "Department",
        type: "string",
        minWidth: 120,
        flex: 1,
      },
      {
        field: "designation",
        headerName: "Designation",
        description: "Designation",
        type: "string",
        minWidth: 120,
        flex: 1,
      },

      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete user">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit user">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Suspend user">
                  <PauseCircleFilledOutlinedIcon />
                </Tooltip>
              }
              label="Suspend"
              onClick={openSuspendDialog(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [openDeleteDialog, editRow]
  );

  return (
    <Paper sx={{ height: 600, width: "100%", marginTop: 2, padding: 4 }}>
      <DataGrid
        columns={columns}
        rows={employeesData}
        sx={{
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
      <EditEmployeeForm
        open={isEditDialogOpen}
        formik={formik}
        onClose={closeEditDialog}
      />
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteUser}
        title={`Delete ${rowToDelete?.fullName}`}
        msg={`Are you sure you want to delete ${rowToDelete?.fullName}?`}
      />
      <DialogAlert
        open={isSuspendDialogOpen}
        onClose={closeSuspendDialog}
        handleYes={suspendUser}
        title={`Suspend ${rowToSuspend?.fullName}`}
        msg={`Are you sure you want to suspend ${rowToSuspend?.fullName}?`}
      />
    </Paper>
  );
}

export default EmployeeTable;
