import StyledPaper from './StyledPaper';

function MuiDemo() {
  return (
    <>
      <StyledPaper />
    </>
  );
}

export default MuiDemo;
