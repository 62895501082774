import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: false,
  plugins: {
    legend: {
      display: false,
      //   position: "top",
    },
    title: {
      display: true,
      text: "Number of users added to Daily Doc",
    },
  },
};

// const labels = [
//   "Access granted",
//   "Registered",
//   "Suspended",
//   //  "Deleted"
// ];

export default function UserStatusBarChart({ userCounts }) {
  const data = {
    labels: userCounts?.labels,
    datasets: [
      {
        label: "Daily Doc users",
        data: userCounts?.data,
        backgroundColor: [
          // "#10B981",
          "#FFB020",
          "#5048E5",
          "#D14343",
        ],
      },
    ],
  };

  return (
    <Paper
      sx={{
        height: "100%",
        width: "100%",
        boxShadow: 8,
        paddingLeft: { xs: 0.5, sm: 2, md: 4 },
        paddingRight: { xs: 0.5, sm: 2, md: 4 },
        paddingTop: { xs: 2, sm: 3, md: 4 },
        paddingBottom: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Bar options={options} data={data} width={270} height={350} />
      </Box>
    </Paper>
  );
}
