import {
  LOAD_COUNTRYLIST,
  LOAD_STATELIST,
  LOAD_CITYLIST,
  REMOVE_COUNTRY_LIST,
  REMOVE_STATE_LIST,
  REMOVE_CITY_LIST,
  LOAD_ADMIN_PANEL_ROLES,
  REMOVE_ADMIN_PANEL_ROLES,
} from "./listTypes";

const INITIAL_STATE = {
  countryList: [],
  isCountryListLoading: true,
  stateList: [],
  isStateListLoading: true,
  cityList: [],
  isCityListLoading: true,
  creditTypesList: [],
  isAdminPanelRolesListLoading: true,
  adminPanelRolesList: [],
};

const listReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_COUNTRYLIST:
      return {
        ...state,
        countryList: action.payload,
        isCountryListLoading: false,
      };
    case LOAD_STATELIST:
      return {
        ...state,
        stateList: action.payload,
        isStateListLoading: false,
      };
    case LOAD_CITYLIST:
      return {
        ...state,
        cityList: action.payload,
        isCityListLoading: false,
      };
    case LOAD_ADMIN_PANEL_ROLES:
      return {
        ...state,
        adminPanelRolesList: action.payload,
        isAdminPanelRolesListLoading: false,
      };
    case REMOVE_COUNTRY_LIST:
      return {
        ...state,
        countryList: [],
        isCountryListLoading: true,
      };
    case REMOVE_STATE_LIST:
      return {
        ...state,
        stateList: [],
        isStateListLoading: true,
      };
    case REMOVE_CITY_LIST:
      return {
        ...state,
        cityList: [],
        isCityListLoading: true,
      };
    case REMOVE_ADMIN_PANEL_ROLES:
      return {
        ...state,
        adminPanelRolesList: [],
        isAdminPanelRolesListLoading: true,
      };
    default:
      return state;
  }
};

export default listReducer;
