import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/system";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";

import api from "../../utilities/api";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { loadUserAction } from "../../redux/auth/authAction";

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const validation = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
});

export default function Profile() {
  const hospital = useSelector((state) => state.auth.hospital);
  const user = useSelector((state) => state.auth.user);
  const { firstName, lastName, email, mobile, countryCode, _id, createdAt } =
    user;

  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      user: {
        firstName: values.firstName,
        lastName: values.lastName,
      },
    };

    try {
      const response = await api.patch(
        `/hospitals/${hospital._id}/users/${user._id}`,
        formData
      );
      dispatch(loadUserAction(response.data.data.user));
      dispatch(showSnackbarAction(response.data.message, "success"));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    mobile: mobile || "",
    _id: _id || "",
    countryCode: countryCode || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const createdString = `Created: ${new Date(
    Date.parse(createdAt)
  ).toLocaleString()}`;
  const avatarInitial = firstName.charAt(0).toUpperCase();
  return (
    <Card sx={{ marginTop: 2, padding: { xs: 1, sm: 4 } }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "neutral.[500]" }}>{avatarInitial}</Avatar>
        }
        title="Profile Info"
        subheader={createdString}
      />
      <CardContent>
        <StyledForm
          className="profile"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <Grid container spacing={{ xs: 2 }}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                // sx={{ input: { color: "text.disabled" } }}
                // size="small"
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                // sx={{ input: { color: "text.disabled" } }}
                // size="small"
                fullWidth
                id="lastName"
                name="lastName"
                label="Last Name"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                // size="small"
                disabled
                fullWidth
                id="email"
                name="email"
                label="Email"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                // size="small"
                disabled
                fullWidth
                id="mobile"
                name="mobile"
                label="Mobile"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Button
                size="large"
                fullWidth
                type="submit"
                color="primary"
                variant="outlined"
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Button
                size="large"
                type="button"
                // sx={{ float: 'right' }}
                fullWidth
                color="secondary"
                variant="outlined"
                onClick={() => formik.resetForm(initialValues)}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
      </CardContent>
    </Card>
  );
}
