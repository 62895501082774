import {
  ACCESSCONTROL_DATA_LOADED,
  ACCESSCONTROL_ERROR,
  ACCESSCONTROL_MOBILE_ADDED,
} from './AccessControlTypes';
const initialState = {
  isLoading: true,
  isError: false,
  accessControlData: [],
};

function accessControlReducer(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case ACCESSCONTROL_DATA_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        accessControlData: payload,
      };

    case ACCESSCONTROL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        accessControlData: [],
      };

    case ACCESSCONTROL_MOBILE_ADDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default accessControlReducer;
