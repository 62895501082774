import Container from "@mui/material/Container";
import AccessControlToolbar from "../../components/access-control/AccessControlToolbar";
import AccessControlTable from "../../components/access-control/AccessControlTable";
import PageHeading from "../../components/reusables/PageHeading";
import Box from "@mui/material/Box";
import AccessControlAction from "../../components/access-control/AccessControlAction";

function AccessControlPage() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ marginBottom: 2 }}>
        <PageHeading heading="Users" action={<AccessControlAction />} />
      </Box>
      <AccessControlToolbar />
      <AccessControlTable />
    </Container>
  );
}

export default AccessControlPage;
