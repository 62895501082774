import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import CardItem from "./CardItem";
import MyDialog from "../dialog/MyDialog";
import { getPrice } from "./util";

export default function SubscriptionCard({ plan, features }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
    navigate("/contact");
  };

  return (
    <Paper
      sx={{
        maxWidth: 400,
        padding: { xs: 1, sm: 2, md: 5 },
        borderRadius: 5,
      }}
    >
      <Box sx={{ my: 2 }}>
        <Button
          onClick={() => {
            if (plan?.title === "Enterprise") {
              openDialog();
            } else {
              navigate("/onboarding");
            }
          }}
          variant="outlined"
          sx={{
            width: "100%",
            height: { xs: "76px", sm: "76px", md: "auto" },
            borderRadius: { xs: 2, sm: 3, md: 5 },
          }}
        >
          {plan?.title === "Enterprise"
            ? "Contact to start this plan"
            : "Signup for this plan"}
        </Button>
      </Box>
      <Box>
        <Box
          sx={{
            mxWidth: "100%",
            height: {
              xs: "200px",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getPrice(plan?.currency, plan?.price, plan?.discountPercentage)}
          </Box>
          <Typography
            mb={1}
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "1.4rem",
                sm: "2.25rem",
              },
            }}
          >
            {plan?.title}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {plan?.subtitle}{" "}
          </Typography>
        </Box>
        {features?.map((feature) => (
          <Box
            key={feature.feature}
            sx={{
              marginBottom: { xs: 0, sm: 1 },
            }}
          >
            <CardItem feature={feature} planTitle={plan?.title} />
          </Box>
        ))}
      </Box>
      <MyDialog
        handleYes={handleYes}
        open={open}
        closeDialog={closeDialog}
        text="To start Enterprise plan, contact Daily Doc by WhatsApp on +1 6124225444 or send a message using contact form"
      />
    </Paper>
  );
}
