import {
  FEATURES_LOADED,
  PLANS_LOADED,
  SUBSCRIPTION_LOADED,
} from './subscriptionTypes';
const initialState = {
  isLoading: true,
  plans: [],
  features: [],
  hospital: null,
};
export default function subscriptionReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FEATURES_LOADED:
      return {
        ...state,
        isLoading: false,
        features: payload,
      };
    case PLANS_LOADED:
      return {
        ...state,
        isLoading: false,
        plans: payload,
      };
    case SUBSCRIPTION_LOADED:
      return {
        ...state,
        isLoading: false,
        hospital: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
