import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import EditOnboarding from "../../components/edit-onboarding/EditOnboarding";

export default function EditHospitalOnboardingPage() {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated } = auth;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // If authenticated, redirect the user to the home page
  if (isAuthenticated) {
    return <Navigate to="/" replace={true} />;
  }
  // Otherwise, render the onboarding component
  return <EditOnboarding />;
}
