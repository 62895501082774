import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import GetAppIcon from "@mui/icons-material/GetApp";
import CSVDownloader from "./CSVDownloader";

export default function AccessControlAction() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: 2,
        flexWrap: "wrap",
      }}
    >
      <Button
        sx={{ padding: 0.5 }}
        startIcon={<GetAppIcon />}
        color="secondary"
      >
        <CSVDownloader />
      </Button>

      <Button
        sx={{ padding: 0.5 }}
        onClick={() => navigate("/dashboard/instructions/add-users")}
        startIcon={<HelpCenterIcon />}
        color="secondary"
      >
        Instructions
      </Button>
    </Box>
  );
}
