import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/auth/authAction";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AddIcon from "@mui/icons-material/Add";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { drawerActiveListBackgroundColor } from "./utilAminNavbar";
import { drawerMenuOptions } from "./utilAminNavbar";
import { hasChildren } from "./utilAminNavbar";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function DrawerList({ handleDrawerClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: 90,
            margin: 2,
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography
            noWrap
            variant="subtitle2"
            sx={{
              color: (theme) => theme.palette.neutral[100],
            }}
          >
            {capitalizeFirstLetter(auth.hospital.name)}
          </Typography>
          <Typography
            noWrap
            variant="caption"
            sx={{
              color: (theme) => theme.palette.neutral[100],
            }}
          >
            Hello {capitalizeFirstLetter(user.firstName)}
          </Typography>
        </Box>
        <Divider sx={{ borderColor: "#2D3748" }} />
        {/** Drawer menu list goes here. See  */}
        <List
          sx={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          {drawerMenuOptions.map((item, key) => (
            <MenuItem
              key={key}
              item={item}
              handleDrawerClose={handleDrawerClose}
            />
          ))}
        </List>
        <Box
          onClick={() => {
            navigate("/dashboard/schedules");
            handleDrawerClose(false);
          }}
          sx={{
            // margin: 2,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 1,
            marginBottom: 1,
            minHeight: 120,
            width: "85%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 2,
            backgroundColor: "rgba(255,255,255, 0.04)",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: drawerActiveListBackgroundColor,
            },
            ...(location.pathname === "/dashboard/schedules" && {
              backgroundColor: drawerActiveListBackgroundColor,
              color: "secondary.main",
              fontWeight: "medium",
            }),
          }}
        >
          <Typography variant="h6">Schedules</Typography>
          <Typography pb={1} variant="body2">
            Add Schedules
          </Typography>
          <AddIcon />
        </Box>
        <List
          sx={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <ListItem
            disableGutters
            sx={{
              mb: 0.5,
              py: 1,
              px: 3,
              borderRadius: 1,
              "&:hover": {
                backgroundColor: drawerActiveListBackgroundColor,
              },
            }}
          >
            <ListItemButton
              onClick={() => {
                dispatch(logoutAction());
                navigate("/");
                handleDrawerClose(false);
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: "inherit",
                  fontWeight: "medium",
                  variant: "body2",
                }}
                primary="Logout"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </>
    </>
  );
}

export default DrawerList;

const MenuItem = ({ item, handleDrawerClose }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} handleDrawerClose={handleDrawerClose} />;
};

const SingleLevel = ({ item, handleDrawerClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ListItem
      disableGutters
      sx={{
        mb: 0.5,
        py: 0,
        px: 3,
        borderRadius: 1,
        ...(location.pathname === item.path && {
          backgroundColor: drawerActiveListBackgroundColor,
          color: "secondary.main",
          fontWeight: "medium",
        }),
        "&:hover": {
          backgroundColor: drawerActiveListBackgroundColor,
        },
      }}
    >
      <ListItemButton
        onClick={() => {
          navigate(item.path);
          handleDrawerClose(false);
        }}
      >
        <ListItemIcon fontSize="small" sx={{ color: "inherit" }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            color: "inherit",
            fontWeight: "medium",
            variant: "body2",
          }}
          primary={item.text}
        />
      </ListItemButton>
    </ListItem>
  );
};

const MultiLevel = ({ item, handleDrawerClose }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  // const location = useLocation();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          mb: 0.5,
          py: 0,
          px: 3,
          borderRadius: 1,
          // write a conditonal to say that location.pathname matches one of the children's pathname
          // ...(open &&
          //   location.pathname === children && {
          //     backgroundColor: drawerActiveListBackgroundColor,
          //     color: 'secondary.main',
          //     fontWeight: 'medium',
          //   }),
          "&:hover": {
            backgroundColor: drawerActiveListBackgroundColor,
          },
        }}
        secondaryAction={open ? <ExpandMoreIcon /> : <NavigateNextIcon />}
      >
        <ListItemButton onClick={handleClick}>
          <ListItemIcon fontSize="small" sx={{ color: "currentColor" }}>
            {item.icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              color: "inherit",
              fontWeight: "medium",
              variant: "body2",
            }}
            primary={item.text}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem
              key={key}
              item={child}
              handleDrawerClose={handleDrawerClose}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};
