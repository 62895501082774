import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ManageSubscription from "../../components/subscription/ManageSubscription";
import CurrentPlan from "../../components/subscription/CurrentPlan";
import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSubscriptionData } from "../../redux/subscription/subscriptionAction";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import api from "../../utilities/api";
import { getPlansAndFeatures } from "../../components/subscription/util";

function SubscriptionPage() {
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const getHospitalData = async () => {
    try {
      const response = await api.get(`/hospitals/${hospital?._id}`);
      dispatch(loadSubscriptionData(response?.data?.data?.hospital));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };
  useEffect(() => {
    getHospitalData(hospital._id);
    return () => {};
  }, [hospital]);

  useEffect(() => {
    getPlansAndFeatures();
  }, []);

  return (
    <Container maxWidth="lg">
      <Box mb={5}>
        <CurrentPlan />
      </Box>
      <Box mb={5}>
        <Paper
          sx={{
            padding: 5,
            height: 400,
            textAlign: "center",
          }}
        >
          Statemets
        </Paper>
      </Box>
      <Box>
        <ManageSubscription />
      </Box>
    </Container>
  );
}

export default SubscriptionPage;
