import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";

export default function FeatureItem({ feature }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <DoneIcon color="primary" fontSize="10px" />
      <Typography variant="subtitle2">{feature?.feature}</Typography>
    </Box>
  );
}
