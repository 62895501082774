import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "../../utilities/countries";

export default function NewAdminDetails({ formik }) {
  return (
    <Box>
      {/* <Typography
        sx={{
          marginBottom: 4,
        }}
      >
        New Admin Details
      </Typography> */}
      <Box>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextField
              name="firstName"
              autoComplete="off"
              fullWidth
              label="New admin's first name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              name="lastName"
              autoComplete="off"
              fullWidth
              label="New admin's last name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              name="email"
              autoComplete="off"
              fullWidth
              label="New admin's email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid size={6}>
            <Autocomplete
              id="country-select-demo"
              //   sx={{ width: 300 }}
              //   isOptionEqualToValue={(option, value) =>
              //     option?.value === value?.value
              //   }
              value={formik.values.country}
              name="country"
              onChange={(event, newValue) => {
                formik.setFieldValue("country", newValue);
              }}
              options={countries || []}
              autoHighlight
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box
                    key={key}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...optionProps}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country code for mobile"
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    },
                  }}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
              )}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              name="mobile"
              autoComplete="off"
              fullWidth
              label="New admin's mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
