import {
  FEATURES_LOADED,
  PLANS_LOADED,
  SUBSCRIPTION_LOADED,
} from './subscriptionTypes';

export const loadPlansAction = (data) => (dispatch) => {
  dispatch({
    type: PLANS_LOADED,
    payload: data,
  });
};
export const loadFeaturesAction = (data) => (dispatch) => {
  dispatch({
    type: FEATURES_LOADED,
    payload: data,
  });
};

export const loadSubscriptionData = (data) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_LOADED,
    payload: data,
  });
};
