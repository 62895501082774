import { useCSVReader } from "react-papaparse";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Upload as UploadIcon } from "../icons/Upload";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { validateCSV } from "./csvUtil";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  browseFile: {
    width: "20%",
  },
  acceptedFile: {
    border: "1px solid #ccc",
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
  },
  remove: {
    borderRadius: 0,
    padding: "0 20px",
  },
  progressBarBackgroundColor: {
    backgroundColor: "gray",
  },
};

const Input = styled("input")({
  display: "none",
});

export default function CSVReader({ setCSVReaderData }) {
  const { CSVReader } = useCSVReader();

  return (
    <>
      <CSVReader
        config={{ header: true }}
        onUploadAccepted={(results) => {
          const validationResult = validateCSV(results.data);
          setCSVReaderData(validationResult.data);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <>
            <Box sx={{ marginTop: 5 }}>
              <Card
                sx={{
                  minWidth: 300,
                  maxWidth: 600,
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                <CardHeader
                  title="Bulk Upload Users"
                  subheader="Instruction link in right upper corner"
                  sx={{ py: 3, px: 2 }}
                />
                <form>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <label htmlFor="upload-csv">
                        <Button startIcon={<UploadIcon />} {...getRootProps()}>
                          Browse CSV Files
                        </Button>
                      </label>
                      <Typography>
                        {acceptedFile && acceptedFile.name}
                      </Typography>
                    </Box>

                    <ProgressBar style={styles.progressBarBackgroundColor} />

                    <Box
                      sx={{
                        marginTop: 3,
                        marginBottom: 1,
                      }}
                    >
                      <Box>
                        <Button
                          sx={{ mx: 1, my: 1 }}
                          startIcon={<RestartAltIcon />}
                          variant="contained"
                          color="secondary"
                          {...getRemoveFileProps()}
                        >
                          Reset
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Card>
            </Box>
          </>
        )}
      </CSVReader>
    </>
  );
}
