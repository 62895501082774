import { useEffect } from "react";
import Box from "@mui/material/Box";
import Hero from "../../components/home/Hero";
import MainFeatures from "../../components/home/MainFeatures";
import WhatAreYouWaiting from "../../components/home/WhatAreYouWaiting";
import AllFeatures from "../../components/home/AllFeatures";
import Typography from "@mui/material/Typography";

function HomePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <Box>
        <Hero />
      </Box>
      <Box>
        <MainFeatures />
      </Box>
      <Box mb={8}>
        <AllFeatures />
      </Box>
      <Box mb={8}>
        <Typography
          mb={5}
          variant="h2"
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "2rem", // For extra small screens (mobile)
              sm: "3rem", // h2 default size for all other screens
            },
          }}
        >
          Get Daily Doc for secure messaging !!
        </Typography>
        <WhatAreYouWaiting />
      </Box>
    </Box>
  );
}

export default HomePage;
