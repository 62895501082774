import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import PrivacyPolicy from "../../components/privacy-policy/PrivacyPolicy";
import { useEffect } from "react";

export default function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <PrivacyPolicy />
    </Box>
  );
}
