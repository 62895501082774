import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function CardItem({ feature, planTitle }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 1, sm: 2 },
        flexWrap: 'nowrap',
      }}
    >
      {feature?.plans?.includes(planTitle) ? (
        <DoneIcon color="success" fontSize="10px" />
      ) : (
        <CloseIcon color="success" fontSize="10px" />
      )}
      <Typography variant="subtital2">{feature?.feature}</Typography>
    </Box>
  );
}
