import WardsForm from '../../components/wards/WardsForm';
import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import WardsTable from '../../components/wards/WardsTable';

function WardsPage() {
  return (
    <>
      <Container maxWidth='lg'>
        <PageHeading heading='Wards' />
        <WardsForm />
        <WardsTable />
      </Container>
    </>
  );
}

export default WardsPage;
