import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function PageHeading({ heading, subtitle, action }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 2,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h4">{heading}</Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {subtitle}
        </Typography>
      </Box>
      <Box>{action}</Box>
    </Box>
  );
}

export default PageHeading;
