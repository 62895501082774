import api from "../../utilities/api";
import { loadBedsAction } from "../../redux/beds/bedsAction";
import { store } from "../../redux/store";
import { showSnackbarAction } from "../../redux/alert/alertAction";

export const getBedsData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/wards/beds`;
  const response = await api.get(url);
  if (response) store.dispatch(loadBedsAction(response?.data?.data?.allBeds));
  try {
    const response = await api.get(url);
    if (response) store.dispatch(loadBedsAction(response?.data?.data?.allBeds));
  } catch (err) {
    store.dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
  }
};
