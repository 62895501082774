import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
export default function ConfirmRegistration() {
  return (
    <Box mb={5}>
      <Typography sx={{ textAlign: "center", marginBottom: 5 }} variant="h6">
        Confirm you want to register by clicking on submit button !!
      </Typography>
      <Divider />
    </Box>
  );
}
