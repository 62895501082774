import Container from "@mui/material/Container";
import GettingStarted from "../../components/instructions/GettingStarted";

export default function GettingStartedPage() {
  return (
    <Container maxWidth="lg">
      <GettingStarted />
    </Container>
  );
}
