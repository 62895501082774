import { useMemo, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import DialogAlert from "../alerts/DialogAlert";

import EditCSVRow from "./EditCSVRow";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import api from "../../utilities/api";
import { validateCSV } from "./csvUtil";

function AccessControlCsvTable({ CSVReaderData, setCSVReaderData }) {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState("");

  const navigate = useNavigate();
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const addCsvData = async () => {
    const result = validateCSV(CSVReaderData);
    if (result.isErr) {
      setCSVReaderData(result.data);
      dispatch(
        showSnackbarAction("Correct errors before submitting csv data", "error")
      );
      return;
    }
    const formData = { users: CSVReaderData };
    const url = `/hospitals/${hospital._id}/employees`;
    try {
      const response = await api.post(url, formData);

      if (response.data?.metadata?.length > 0) {
        setCSVReaderData(response.data?.metadata);
      } else {
        navigate("/dashboard/access-control");
      }
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  // edit row
  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );

  // close dialoge
  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete([]);
  };

  const deleteRow = async () => {
    setCSVReaderData(
      CSVReaderData.filter((item, indx) => item.id !== rowToDelete.id)
    );
    closeDeleteDialog();
  };

  const onCellEditCommit = (cellData) => {
    const { id, field, value } = cellData;
    const modifedData = CSVReaderData.map((item, indx) =>
      indx === id - 1 ? { ...item, [field]: value } : item
    );
    const result = validateCSV(modifedData);
    setCSVReaderData(result.data);
  };

  const saveRowData = (values, actions) => {
    const { id, countryCode, mobile } = values;
    const modifedData = CSVReaderData.map((item, indx) =>
      indx === id - 1 ? { ...item, mobile, countryCode } : item
    );
    const result = validateCSV(modifedData);
    setCSVReaderData(result.data);
    setisEditDialogOpen(false);
  };

  const columns = useMemo(
    () => [
      // { field: 'id', type: 'string', minWidth: 150, flex: 1 },
      {
        field: "id",
        headerName: "Item No",
        description: "Item No",
        type: "string",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "countryCode",
        headerName: "Country code",
        description: "Country Code for mobile wihout any plus sign",
        type: "string",
        minWidth: 150,
        flex: 1,
        editable: true,
      },
      {
        field: "mobile",
        headerName: "Mobile Number",
        description: "Mobile number",
        type: "string",
        minWidth: 150,
        flex: 1,
        editable: true,
      },
      {
        field: "message",
        headerName: "Errors",
        description: "Anny issues with data",
        type: "string",
        minWidth: 360,
        flex: 1,
        renderCell: ({ row }) => {
          return <Typography color="error.main">{row.message}</Typography>;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 200,
        flex: 2,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete row">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit row">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 6,
          padding: 4,
          height: 800,
          width: "100%",
          marginBottom: 4,
          ...(!CSVReaderData?.length > 0 && { display: "none" }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">CSV Data</Typography>
          <Button
            startIcon={<NorthEastIcon />}
            variant="contained"
            onClick={addCsvData}
          >
            Upload Data
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={CSVReaderData}
            // getRowId={(row) => row.internalId}
            // onCellEditStop={(params, event) => {
            //   if (params.reason === GridCellEditStopReasons.cellFocusOut) {
            //     event.defaultMuiPrevented = true;
            //   }
            // }}
            onCellEditCommit={onCellEditCommit}
            sx={{
              ...(!CSVReaderData?.length > 0 && { display: "none" }),
              border: 2,
              borderColor: "divider",
              "& .MuiDataGrid-cell": {
                borderBottom: 2,
                borderBottomColor: "divider",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: 2,
                borderBottomColor: "divider",
              },
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </Box>
      </Paper>
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteRow}
        title={`Delete row with ${rowToDelete?.mobile}`}
        msg={
          deleteErr ||
          `Are you sure you want to delete row: ${rowToDelete?.mobile}?`
        }
      />
      {/* <DialogAlert
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        handleYes={closeEditDialog}
        title="Edit instructions"
        msg="To Edit contents of a cell, double click on the cell, it will be highlighted. Then you can edit it. To save changes, hit enter button or click outside the edited cell, or hit tab key. On hitting escape key, changes will undone."
      /> */}
      <EditCSVRow
        saveRowData={saveRowData}
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        rowToEdit={rowToEdit}
      />
    </>
  );
}

export default AccessControlCsvTable;
