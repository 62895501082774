import TextField from '@mui/material/TextField';

function SecondPage(props) {
  const { formik } = props;
  return (
    <div>
      <TextField
        fullWidth
        id='password'
        name='password'
        label='Password'
        type='password'
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <TextField
        fullWidth
        id='confirmpPassword'
        name='confirmPassword'
        label='confirm Password'
        type='confirmpPassword'
        value={formik.values.confirmpPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmpPassword &&
          Boolean(formik.errors.confirmpPassword)
        }
        helperText={
          formik.touched.confirmpPassword && formik.errors.confirmpPassword
        }
      />
    </div>
  );
}

export default SecondPage;
